import { isChrome, isWechat } from '@/common/utils'
export const state = {
  _innerStateMachine: {
    answersheet: false, // 是否显示answersheet
    ploading: false, // 题目加载loading
    incompleteQuestion: false, // 试题未完全作答提示弹窗开关
    incomplete: false, // 试卷未完成提示弹窗开关
    complete: false, // 试卷题目全部完成提示检查一下弹窗
    illePaper: false, // 试卷是否合法，控制试卷不合法弹窗 confirmModalVisible
    illePaperClose: true, // 试卷是否合法弹窗，是否展示右上角关闭icon
    illePaperbtns: true, // 试卷是否合法弹窗，是否展示按钮操作，默认展示
    illePaperInfo: '试卷走丢了，请联系管理员老师或秃头~', // 试卷是否合法，控制试卷不合法弹窗 文案
    isForceSubmit: false, // 是否强制提交，默认为false
    showGoldCoins: false, // 是否显示金币弹窗
    goldcoins: 0, // 金币数量
    snapshot: 0, // 枚举值: 0-初始值，题卡切题的值；1-快照；2-正式
    chrome: isChrome(), // 是否显示chrome 检测弹窗
    weChat: isWechat(), // 是否显示微信 检测弹窗
    statement: '', // 当前展示的说明类型
    skStartBtn: false, // 赛考阅读说明页面【开始考试】按钮
    cutdownmsg: '倒计时', // 倒计时文案
    timeover: false, // 时间耗尽弹窗
    cutout: false, // 切屏检测弹窗
    checkcamera: false, // 提醒检测摄像头弹窗
    devicedetect: false, // 设备检测功能弹窗
    guide: false, // 是否显示新手引导
    trymode: false // 再试一次模式：空白｜｜修正
  } // 试卷内部交互状态机
}
