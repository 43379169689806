<template>
  <button
    type="button"
    class="xes-button"
    :class="{
      [`xes-button--${type}`]: true,
      [`xes-button--full`]: full,
      [`xes-button--${size}`]: size !== '',
      [`xes-button--full-refund`]: fullRefund,
      [`xes-is-disabled`]: disabled,
      [`xes-is-disabled--${type}`]: disabled,
      [`xes-is-loading`]: loading,
      [`xes-is-loading--${type}`]: loading
    }"
    :disabled="disabled"
    :style="styles"
    @click="handleClick"
  >
    <svg v-if="loading" viewBox="25 25 50 50" class="xes-button-circular">
      <circle cx="50" cy="50" r="20" fill="none" class="xes-button-path"></circle>
    </svg>
    <span>
      <slot></slot>
    </span>
  </button>
</template>

<script>
export default {
  name: 'XesButton',

  props: {
    // 类型, default, primary, danger
    // 新增类型 浮雕类型 relief
    type: {
      type: String,
      default: 'default',
      required: false
    },

    // 按钮的大小, medium, small, mini
    size: {
      type: String,
      default: '',
      required: false
    },

    // 是否为全圆角
    fullRefund: {
      type: Boolean,
      default: false,
      required: false
    },

    // 是否占满宽度
    full: {
      type: Boolean,
      default: false,
      required: false
    },

    // 自定义样式
    styles: {
      type: Object,
      default () {
        return {}
      },
      required: false
    },

    // 是否为禁用的状态
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },

    // 按钮是否处于loading的状态
    loading: {
      type: Boolean,
      default: false,
      required: false
    }
  },

  methods: {
    handleClick () {
      if (!this.loading) {
        this.$emit('click')
      }
    }
  }
}
</script>

<style lang="less" scoped>
/** 基础样式 */
.xes-button {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  border-color: #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  transition: .1s;
  font-weight: 500;
  user-select: none;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
  vertical-align: middle;
  &--relief {
    box-shadow: inset 0 -3px 0 0 rgba(0,0,0,.2);
  }
}

.xes-is-disabled {
  color: #c0c4cc;
  cursor: not-allowed;
  background-image: none;
  background-color: #fff;
  border-color: #ebeef5;
}

.xes-is-loading {
  color: #c0c4cc;
  cursor: default;
  background-image: none;
  background-color: #fff;
  border-color: #ebeef5;
}

.xes-button--full {
  width: 100%;
}

.xes-button--primary {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  &.xes-is-disabled--primary {
    color: #fff;
    background-color: #a0cfff;
    border-color: #a0cfff;
  }
  &.xes-is-loading--primary {
    color: #fff;
    background-color: #a0cfff;
    border-color: #a0cfff;
  }
}

.xes-button--danger {
  color: #fff;
  background-color: #f03438;
  border-color: #f03438;
  &.xes-is-disabled--danger {
    color: #fff;
    background-color: #fab6b6;
    border-color: #fab6b6;
  }
  &.xes-is-loading--danger {
    color: #fff;
    background-color: #fab6b6;
    border-color: #fab6b6;
  }
}

.xes-button--medium {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 4px;
}

.xes-button--small {
  padding: 9px 15px;
  font-size: 12px;
  border-radius: 3px;
}

.xes-button--mini {
  padding: 7px 15px;
  font-size: 12px;
  border-radius: 3px;
}

.xes-button--full-refund {
  border-radius: 100px;
}

.xes-button-circular {
  height: 14px;
  width: 14px;
  animation: loading-rotate 2s linear infinite;
  .xes-button-path {
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90,150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: #fff;
    stroke-linecap: round;
  }
}
</style>
