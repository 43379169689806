import mqtt from 'mqtt'
import { EVENT_NAME } from '@/common/config'
import Event from '@/common/emitter'
import { getCookieStuId, isTeacherEnd } from '@/common/utils'

export default class mqttManager {
  constructor (data) {
    const { url, clientid, username, password, topics } = data
    this.url = url
    this.client = null
    this.topicUp = topics.up
    this.topicDown = topics.down
    this._options = {
      clientid,
      reconnectPeriod: 2000,
      keepalive: 20,
      username, // add at 0331 mqtt 添加username 和 password
      password
    }
    this.msgOptions = {
      qos: 1
    }
  }

  open () {
    return new Promise((resolve, reject) => {
      const dec = new TextDecoder()
      this.client = mqtt.connect(this.url, this._options)
      this.client.on('connect', () => {
        this.client.subscribe(this.topicDown, this.msgOptions, (err) => {
          if (err) {
            console.error('[订阅主题发生错误]', this.topicDown, err)
            window.logger.sendLog('click', {
              clickname: 'jiuge_paper-mqtt-connect',
              click_value: '订阅主题发生错误' + this.topicDown,
              errmsg: JSON.stringify(err),
              userid: getCookieStuId(),
              specificvalue: isTeacherEnd() ? 'teacher' : 'student'
            })
          }
        })
        console.log('[订阅主题 成功]', this.topicDown, this._options)
        window.logger.sendLog('click', {
          clickname: 'jiuge_paper-mqtt-connect',
          click_value: '订阅主题成功' + this.topicDown,
          errmsg: JSON.stringify(this._options),
          userid: getCookieStuId(),
          specificvalue: isTeacherEnd() ? 'teacher' : 'student'
        })
        resolve()
      })
      this.client.on('message', (topic, message) => {
        message = dec.decode(message)
        Event.$emit(EVENT_NAME.MQTT_MESSAGE, message)
        console.log('[mqtt message]: ', message)
      })
      this.client.on('error', (err) => {
        console.log('[mqtt error]: ', err)
        window.logger.sendLog('click', {
          clickname: 'jiuge_paper-mqtt-connect',
          click_value: 'mqtt error',
          errmsg: JSON.stringify(err),
          userid: getCookieStuId(),
          specificvalue: isTeacherEnd() ? 'teacher' : 'student'
        })
      })
      this.client.on('close', (topic, message) => {
        console.log('[mqtt close]')
        window.logger.sendLog('click', {
          clickname: 'jiuge_paper-mqtt-connect',
          click_value: 'mqtt close',
          errmag: JSON.stringify(message),
          userid: getCookieStuId(),
          specificvalue: isTeacherEnd() ? 'teacher' : 'student'
        })
      })
    })
  }

  close () {
    if (this.client) {
      this.client.end()
      this.client = null
    }
  }

  reconnect () {
    console.log('[mqtt reconnect]')
    window.logger.sendLog('click', {
      clickname: 'jiuge_paper-mqtt-connect',
      click_value: 'mqtt reconnect',
      userid: getCookieStuId(),
      specificvalue: isTeacherEnd() ? 'teacher' : 'student'
    })
    this.client.reconnect()
  }

  isconnected () {
    return this.client && this.client.connected
  }

  sendMessage (msg) {
    if (this.client && this.client.connected) {
      this.client.publish(this.topicUp, msg, this.msgOptions, function (err) {
        if (err !== null) {
          console.log('[发送消息失败了]:', msg, err)
          window.logger.sendLog('click', {
            clickname: 'jiuge_paper-mqtt-connect',
            click_value: '[mqtt sendall function error]',
            errmsg: JSON.stringify(err),
            userid: getCookieStuId(),
            specificvalue: isTeacherEnd() ? 'teacher' : 'student'
          })
        }
      })
    }
  }
}
