import { getDataset, setDataset } from '@/common/utils'
const logStat = {}

const install = function (Vue) {
  Vue.directive('stat', {
    bind: function (el, binding) {
      el.addEventListener('click', (event) => {
        const temObj = {}
        const data = binding.value
        const keys = Object.keys(data)
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i]
          if (el.dataset[key]) {
            temObj[key] = getDataset(el, key, 'string')
          } else {
            setDataset(el, key, data[key])
            temObj[key] = data[key]
          }
        }
        window.logger.sendLog('click', temObj, el)
        el.dataset.buttonClickTime = Date.now()
        setDataset(el, 'buttonClickTime', Date.now())
      }, false)
    },
    update: function (el, binding) {
      const newData = binding.value
      const keys = Object.keys(newData)
      for (let i = 0; i < keys.length; i++) {
        const key = keys[i]
        setDataset(el, key, newData[key])
      }
    }
  })
}

logStat.install = install

export default logStat
