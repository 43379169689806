import { state } from './state.js'
import { getters } from './getters.js'
import { actions } from './actions.js'
import { mutations } from './mutations.js'
export default {
  state,
  getters,
  actions,
  mutations
}
