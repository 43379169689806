export const state = {
  practiceInfo: null, // 练习||试卷信息
  problems: null, // 练习||试卷 题目信息
  commitStateList: null, // 提交历史状态信息
  commitList: null, // 提交历史信息
  commit_at_times: 0, // 试卷的提交时间戳
  paperState: -1, // 练习||试卷 所处状态
  apiPaperState: -1, // 接口最原始返回的状态
  commitid: '', // 新的作答id
  currentAnswers: {}, // 目前的答题列表情况
  apiAnswers: {}, // 接口记录中的答题情况，单题提交后变化
  apiAnswersOrigin: {}, // 接口记录中的答题情况
  currentAnswer: null, // 当前作答题目的答案
  currentIndex: 0, // 当前在答的题目索引
  nextPageIndex: 0, // 下一个要去的题目索引
  paperSubmit: false, // 是否需要整卷提交
  paperTime: null, // 考试时长相关信息
  answersTime: null, // 学生作答时长
  userType: '', // 0 未购课 1 长期 2 短期
  userCourse: {}, // 用户课程信息
  pageVisiable: true, // 页面是否可见，默认可见
  pageLeaveTime: 0, // 页面离开时间
  userId: '', // 用户id
  userScore: '' // 用户成绩
}
