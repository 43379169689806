import { message } from './xes-message'
import { feedmsg } from './feed-msg'
import { alert } from './xes-alert'
import XesButton from './xes-button/index.vue'
const XesUI = {
  install (Vue) {
    Vue.component('xes-button', XesButton)
    Vue.prototype.$message = message()
    Vue.prototype.$feedmsg = feedmsg()
    Vue.prototype.$alert = alert()
  }
}
export default XesUI
