import XesMessageGroup from './xes-message-group'
import Vue from 'vue'

export function feedmsg () {
  const XesMessageGroupConstructor = Vue.extend(XesMessageGroup)
  let xesMessageGroup = null
  // return function (message = '', type = 'success') {
  return function (msg = {}) {
    const { message, type } = msg
    if (!xesMessageGroup) {
      xesMessageGroup = new XesMessageGroupConstructor()
      document.body.appendChild(xesMessageGroup.$mount().$el)
      // 添加队列
      xesMessageGroup.addQueue(message, type)
    } else {
      // 直接添加队列
      xesMessageGroup.addQueue(message, type)
    }
  }
}
