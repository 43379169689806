<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
import xes from 'xes-code-base/lib/libs/xes'
import '@/assets/css/iconfont.css'
export default {
}
</script>
<style lang="less">

body {
  margin: 0;
}
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  font-family: PingFang SC, Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
}
* {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  // -webkit-touch-callout: none; /*系统默认菜单被禁用*/
  -webkit-user-select: none; /*webkit浏览器*/
  -khtml-user-select: none; /*早期浏览器*/
  -moz-user-select: none; /*火狐*/
  -ms-user-select: none; /*IE10*/
  user-select: none;
}
input, textarea {
  -webkit-user-select:auto; /*webkit浏览器*/
  outline: none;
}
</style>
