import { getCompetitionInfo, updateDeviceTest, getBisaiDetail, getUserInfo } from '@/http/sk'
export const actions = {
  /**
   * [getCompetition 获取赛事信息]
   * @return {[Promise]}
   */
  async getCompetition ({ rootState, commit }) {
    const { channel } = rootState.route.params
    const info = await getCompetitionInfo({
      channel
    })
    if (!Array.isArray(info)) {
      commit('setCompetition', info)
    }
    return info
  },
  /**
   * [updateDeviceTest 更新设备信息]
   * @return {[Promise]}
   */
  updateDeviceTest ({ commit }, data) {
    return updateDeviceTest(data).then(res => {
      commit('updateDeviceTest')
    })
  },
  /**
   * [getBsDetail 获取比赛详情]
   * @return {[Promise]}
   */
  async getBsDetail ({ commit }, data) {
    const bi_sai_id = data
    const info = await getBisaiDetail({
      bi_sai_id
    })
    commit('setBsDetail', info)
  },

  /**
   * [getBsDetail 获取用户信息]
   * @return {[Promise]}
   */
  async getUser ({ commit }, data) {
    console.log(data,'dataaaaaa')
    const saishi_id = data
    const info = await getUserInfo({
      saishi_id
    })
    console.log(info,'info')
    commit('setUSerInfo', info)
  }
}
