import { isStudentEnd, isCorrectEnd, isTeacherEnd, getQueryString } from '@/common/utils'
const ENV_MAP = {
  h5: 1, // 浏览器
  correct: 2, // 批改端
  student: 3, // 学生端
  teacher: 4 // 教师端
}
function getEnv () {
  let env = 'h5'
  if (isStudentEnd()) {
    env = 'student'
  }
  if (isCorrectEnd()) {
    env = 'correct'
  }
  if (isTeacherEnd()) {
    env = 'teacher'
  }
  return ENV_MAP[env]
}
// 每个点点userid 属性是下游class埋下
// 试卷详情
export const paperDescStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_papera_x_show',
    pumtype: data.ptype,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 开始答题
export const paperStartStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_papera_star_click',
    pumtype: data.ptype,
    postype: data.type, // 按钮位置
    terminal_type: getEnv(),
    start_enable: data.start_enable,
    commitid: data.commitid,
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 答题卡切题
export const paperSheetNumStat = data => {
  const params = {
    clickname: data.clickname,
    pumtype: data.ptype,
    click_value: data.index,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 试卷题目面板
export const paperQuePanelStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_paperb_x_show',
    pumtype: data.ptype,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 提交试卷按钮
export const paperSubmitStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_paperb_submit_click',
    pumtype: data.ptype,
    postype: data.type,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 有未作答题目弹窗展示
export const hasNotDoneDialogShowStat = data => {
  const params = {
    clickname: 'jiuge_codeup_hasnotdone_dialog_show',
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 有未做完题目弹窗，二次确认提交
export const hasNotDoneSubmitClickStat = data => {
  const params = {
    clickname: 'jiuge_codeup_hasnotdone_submit_click',
    click_value: data.click_value,
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 已经都完成，还有时间剩余弹窗展示
export const hasTimeLeftDialogShowStat = data => {
  const params = {
    clickname: 'jiuge_codeup_hastimeleft_dialog_show',
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 有时间剩余，依然提交
export const hasTimeLeftSubmitClickStat = data => {
  const params = {
    clickname: 'jiuge_codeup_hastimeleft_submit_click',
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 题单说明按钮
export const paperDescbtnStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_paperb_back2a_click',
    pumtype: data.ptype,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 上一题按钮
export const paperPrebtnStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_paperb_back_click',
    pumtype: data.ptype,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 下一题按钮
export const paperNextbtnStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_paperb_next_click',
    pumtype: data.ptype,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 提交单题按钮
export const paperSubmitbtnStat = data => {
  const params = {
    clickname: 'jiuge_codeup_tijiaobenti_click',
    userid: data.userid,
    origin: data.origin,
    userType: data.userType,
    userCourse: data.userCourse,
    pno: getQueryString('pno'),
    problemId: data.practice_code,
    clicktime: new Date().getTime(),
    showtime: data.showtime,
    answertime: data.answertime
  }
  window.logger.sendLog('click', params)
}
// 查看解析按钮
export const paperAnalysisbtnStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_paperc_go2d_click',
    pumtype: data.ptype,
    postype: data.type,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 再试一次按钮
export const paperAgainbtnStat = data => {
  const params = {
    clickname: data.clickname,
    pumtype: data.ptype,
    postype: data.type,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 答题统计按钮
export const paperStatisticsStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_paperd_back2c_click',
    pumtype: data.ptype,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 试卷答题统计面板
export const paperCommitListPanelStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_paperc_x_show',
    pumtype: data.ptype,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 试卷答题统计面板
export const paperWaitPanelStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_paperf_x_show',
    pumtype: data.ptype,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 试卷解析面板
export const paperAnalysisPanelStat = data => {
  const params = {
    clickname: 'jiuge_web_codeup_paperd_x_show',
    pumtype: data.ptype,
    terminal_type: getEnv(),
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// pc 相机权限开启成功
export const pcCameraOpenStat = data => {
  const params = {
    clickname: 'jiuge_codeup_camera_open',
    optype: data.type, // 1:实时监控 2:设备检测
    click_value: data.click_value, // 1:成功 2:失败
    bisai_id: data.bisai_id,
    saishi_id: data.saishi_id,
    errmsg: data.errmsg,
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// pc 拍照埋点
export const pcCameraTakeStat = data => { // 拍照
  const params = {
    clickname: 'jiuge_codeup_camera_take',
    optype: data.type, // 1:实时监控 2:设备检测
    click_value: data.click_value, // 1:成功 2:失败
    bisai_id: data.bisai_id,
    saishi_id: data.saishi_id,
    errmsg: data.errmsg,
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// js 错误埋点
export const jsErrorStat = data => {
  const params = {
    clickname: 'jiuge_codeup_js_error',
    errmsg: data.errmsg,
    errext: data.ext ? JSON.stringify(data.ext) : '',
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 使用题库功能埋点
export const useCodeupStat = data => {
  const params = {
    clickname: 'jiuge_codeup_shiyongtikugongneng',
    userid: data.userid,
    origin: data.origin,
    userType: data.userType,
    userCourse: data.userCourse,
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 倒计时初始化
export const cutdownInitStat = data => {
  const params = {
    clickname: 'jiuge_codeup_daojishi_init',
    click_value: data.click_value, // 倒计时类型
    errmsg: data.errmsg, // 倒计时值
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 屏幕激活埋点
export const screenActiveStat = data => {
  const params = {
    clickname: 'jiuge_codeup_pingmujihuo',
    click_value: data.click_value, // 倒计时类型
    errmsg: data.errmsg, // 倒计时接口时间-倒计时前端时间
    apiinfo: data.apiinfo,
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}
// 收到盼题消息
export const rvJudgeMsgStat = data => {
  const params = {
    clickname: 'jiuge_codeup_rv_judge_msg',
    click_value: data.click_value, // 收到的判题数据
    currentQueId: data.currentQueId,
    pno: getQueryString('pno')
  }
  window.logger.sendLog('click', params)
}

// 交卷点击埋点
export const submitPaperStat = data => {
  const params = {
    clickname: 'jiuge_codeup_jiaojuan_click',
    userid: data.userid,
    origin: data.origin,
    userType: data.userType,
    userCourse: data.userCourse,
    problemId: data.problemId,
    clicktime: data.time
  }
  window.logger.sendLog('click', params)
}

// 展示埋点
export const showStat = data => {
  const params = {
    clickname: 'jiuge_codeup_paper_pageshow',
    userid: data.userid,
    userType: data.userType,
    showtime: data.time
  }
  window.logger.sendLog('click', params)
}

//  离开埋点
export const leaveStat = data => {
  const params = {
    clickname: 'jiuge_codeup_paper_pageclose',
    userType: data.userType,
    userid: data.userid,
    closetime: data.time
  }
  window.logger.sendLog('click', params)
}
