let st = 0
let count = 0
const durateTime = 2000 // 2s内连续
function showConsole () {
  if (window.VConsole) {
    const vconsole = new window.VConsole()
    return
  }
  // 插入 vConsole
  const vScript = document.createElement('script')
  vScript.src = '//static0.xesimg.com/talcode/assets/activity/vconsole.js'
  document.body.appendChild(vScript)

  // 异步初始化 vConsole
  vScript.addEventListener('load', () => {
    const vInitScript = document.createElement('script')
    vInitScript.innerHTML = 'var vConsole = new VConsole()'
    document.body.appendChild(vInitScript)
  })
}
function fnHandleClick () {
  const currentTime = new Date().getTime()
  if (!st) {
    st = currentTime
  }
  if (currentTime - st < durateTime) {
    count++
  } else {
    // 重新计数
    count = 0
    st = currentTime
  }
  console.log('------count: ', count)
  if (count > 6) {
    showConsole()
    // 清空数据
    st = 0
    count = 0
    // 取消监听
    rvVconsoleListener()
  }
}
function addVconsoleListener () {
  window.addEventListener('touchend', fnHandleClick, false)
  window.addEventListener('click', fnHandleClick, false)
}
function rvVconsoleListener () {
  window.removeEventListener('touchend', fnHandleClick, false)
  window.removeEventListener('click', fnHandleClick, false)
}
addVconsoleListener()
