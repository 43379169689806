<template>
  <div
    class="xes-message"
    :style="{
      zIndex: zIndex
    }"
  >
    <!-- <i class="xes-message-icon">
      <img v-show="type === 'error'" src="./icon/error.png"/>
      <img v-show="type === 'success'" src="./icon/success.png"/>
      <img v-show="type === 'warning'" src="./icon/warning.png"/>
    </i> -->
    <p class="xes-message-text">{{ message }}</p>
  </div>
</template>

<script>
export default {
  name: 'XesMessage',

  props: {
    // tips的类型
    type: {
      type: String,
      default: 'success',
      required: false
    },

    // tips的文字内容
    message: {
      type: String,
      default: '这是一条tips',
      required: false
    },

    // css的层级
    zIndex: {
      type: Number,
      default: 2500,
      required: false
    },

    // 唯一id
    guid: {
      type: String,
      required: true
    },

    // index 当前tips的索引
    index: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      duration: 2500
    }
  },

  mounted () {
    // 3秒后自动关闭
    setTimeout(() => {
      this.$emit('delete', this.guid)
    }, this.duration)
  }
}
</script>

<style lang="less" scoped>
.xes-message {
  box-sizing: border-box;
  margin-left: -250px;
  border-radius: 4px;
  padding: 15px 15px 15px 20px;
  width: 500px;
  position: fixed;
  left: 50%;
  top: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  pointer-events: all;
  .xes-message-text {
    width: 100%;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    text-align: center;
  }
}
</style>
