// 赛考路由
export const codeupRouter = [
  {
    path: '/qp/index',
    name: 'qp-index',
    component: () => import(/* webpackChunkName: "qp-index" */ '@/views/qpractice/index.vue'),
    meta: {
      title: '练习平台'
    }
  },
  {
    path: '/qp/login',
    name: 'qp-login',
    component: () => import(/* webpackChunkName: "qp-login" */ '@/views/qpractice/login.vue'),
    meta: {
      title: '练习平台'
    }
  },
  {
    path: '/qp/list',
    name: 'qp-list',
    component: () => import(/* webpackChunkName: "qp-list" */ '@/views/qpractice/practiceList.vue'),
    meta: {
      title: '练习平台'
    }
  },
  {
    path: '/qp/video',
    name: 'qp-video',
    component: () => import(/* webpackChunkName: "qp-video" */ '@/views/qpractice/practiceVideo.vue'),
    meta: {
      title: '练习平台'
    }
  },
  {
    path: '/qp/free',
    name: 'qp-free',
    component: () => import(/* webpackChunkName: "qp-free" */ '@/views/qpractice/free'),
    meta: {
      title: '练习平台'
    }
  },
  {
    path: '/qp/videoplay',
    name: 'qp-videoplay',
    component: () => import(/* webpackChunkName: "qp-playe" */ '@/views/qpractice/videoPlayer.vue'),
    meta: {
      title: '练习平台'
    }
  }
]
export default codeupRouter
