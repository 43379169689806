import $axios from 'axios'
import store from '../store'
import Vue from 'vue'

$axios.interceptors.request.use(
  function (config) {
    // 可追加header 标识config.headers[key]
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

$axios.interceptors.response.use(
  function (response) {
    const res = response.data
    if (!res) {
      // 如果返回的为空
      console.error('获取信息失败 [EMPTY_RES]') // 如果未返回有效格式信息
      return Promise.reject(res)
    } else {
      return Promise.resolve(res)
    }
  },
  function (error) {
    if (error && error.response) {
      if (Number(error.response.status) === 401) {
        // 未登录
        store.dispatch('user/login')
      }
    } else {
      // 网络错误
      console.error('网络错误')
      Vue.prototype.$message('网络异常，请检查网络连接')
    }
    return Promise.reject(error)
  }
)
export default $axios
