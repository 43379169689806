export const COOKIE_KEYS = {
  IS_LOGINED: 'is_login', // 使用stu_id 判定是否已经登录
  ENCRY_UID: 'xes_encrypt_uid', // 加密的用户id
  STU_ID: 'stu_id' // 解密的用户id
}
export const LOGOUT_URL = 'https://login.xueersi.com/newLogin/logout/?redirect_url='
export const LOGIN_URL = 'https://login.xueersi.com/?redirect_url=' // 网校登录页面
export const COMMIT_STATE = { // 练习||试卷 所处状态 (1: 完成；0: 未完成；2: 未开始; 3: 开始答题; 4：解析)
  DEFAULT: -1,
  COMPLETE: 1,
  NOT_COMPLETE: 0,
  NOT_CORRECTED: 2,
  CORRECTED: 3,
  NOT_START: 4,
  START: 5,
  ANALYSIS: 6
}
export const PAPER_TYPE = {
  PRACTICE: 1, // 练习 1
  EARMARKED: 2, // 专项
  RACE: 3, // 比赛 3
  EXAM: 4, // 考试 4
  HOMEWORK: 5, // 随堂测
  SK: 6,
  COMMON: 10 // 通用
}
export const PAPER_MODE = {
  PREVIEW: 'preview'
}
export const PAPER_USER_ROLE = {
  ADMIN: 'admin'
}
export const PAPER_ANALYSIS_SHOW = {
  NOT_SET: 0,
  ALWAYS: 1,
  SPECIAL_TIME: 2,
  NEVER_SHOW: 3
}
export const PAPER_COMMIT_REPEAT = {
  REPEAT: -1, // 无限次
  NOT_REPEAT: 1 // 仅一次
}
export const USER_SELF = {
  SELF: true,
  NOT_SELF: false
}
export const MAP_ANSWERSHEET_BTNS = {
  [PAPER_TYPE.PRACTICE]: {
    [USER_SELF.SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.NOT_COMPLETE]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.CORRECTED]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.ANALYSIS]: null
    },
    [USER_SELF.NOT_SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.NOT_COMPLETE]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null
    }
  },
  [PAPER_TYPE.RACE]: {
    [USER_SELF.SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.NOT_COMPLETE]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null
    },
    [USER_SELF.NOT_SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.NOT_COMPLETE]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null
    }
  },
  [PAPER_TYPE.EXAM]: {
    [USER_SELF.SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.NOT_COMPLETE]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null
    },
    [USER_SELF.NOT_SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.NOT_COMPLETE]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null
    }
  },
  [PAPER_TYPE.HOMEWORK]: {
    [USER_SELF.SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.NOT_COMPLETE]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null,
      [COMMIT_STATE.NOT_CORRECTED]: null,
      [COMMIT_STATE.CORRECTED]: {
        text: '提交试卷',
        val: 'SUBMIT'
      }
    },
    [USER_SELF.NOT_SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.NOT_COMPLETE]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null,
      [COMMIT_STATE.NOT_CORRECTED]: null,
      [COMMIT_STATE.CORRECTED]: null
    }
  },
  [PAPER_TYPE.SK]: {
    [USER_SELF.SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: null,
      [COMMIT_STATE.NOT_COMPLETE]: null,
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null,
      [COMMIT_STATE.NOT_CORRECTED]: null,
      [COMMIT_STATE.CORRECTED]: null
    },
    [USER_SELF.NOT_SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: null,
      [COMMIT_STATE.NOT_COMPLETE]: null,
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null,
      [COMMIT_STATE.NOT_CORRECTED]: null,
      [COMMIT_STATE.CORRECTED]: null
    }
  },
  [PAPER_TYPE.COMMON]: {
    [USER_SELF.SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.NOT_COMPLETE]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null,
      [COMMIT_STATE.NOT_CORRECTED]: null,
      [COMMIT_STATE.CORRECTED]: {
        text: '提交试卷',
        val: 'SUBMIT'
      }
    },
    [USER_SELF.NOT_SELF]: {
      [COMMIT_STATE.NOT_START]: null,
      [COMMIT_STATE.START]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.NOT_COMPLETE]: {
        text: '提交试卷',
        val: 'SUBMIT'
      },
      [COMMIT_STATE.COMPLETE]: null,
      [COMMIT_STATE.ANALYSIS]: null,
      [COMMIT_STATE.NOT_CORRECTED]: null,
      [COMMIT_STATE.CORRECTED]: {
        text: '提交试卷',
        val: 'SUBMIT'
      }
    }
  }
}
export const JUDGE_STATUS = {
  '-2': {
    name: 'Compile Error',
    short: 'CE',
    color: 'red',
    type: 'warning'
  },
  '-1': {
    name: 'Wrong Answer',
    short: 'WA',
    color: 'red',
    type: 'error'
  },
  0: {
    name: 'Accepted',
    short: 'AC',
    color: 'green',
    type: 'success'
  },
  1: {
    name: 'CPU Time Limit Exceeded',
    short: 'TLE',
    color: 'red',
    type: 'warning'
  },
  2: {
    name: 'REAL Time Limit Exceeded',
    short: 'TLE',
    color: 'red',
    type: 'warning'
  },
  3: {
    name: 'Memory Limit Exceeded',
    short: 'MLE',
    color: 'red',
    type: 'warning'
  },
  4: {
    name: 'Runtime Error',
    short: 'RE',
    color: 'red',
    type: 'warning'
  },
  5: {
    name: 'System Error',
    short: 'SE',
    color: 'red',
    type: 'warning'
  },
  6: {
    name: 'Pending',
    color: 'blue',
    type: 'info'
  },
  7: {
    name: 'Judging',
    color: 'blue',
    type: 'info'
  },
  8: {
    name: 'Partial Accepted',
    short: 'PAC',
    color: 'yellow',
    type: 'half'
  },
  9: {
    name: 'Submitting',
    color: 'blue',
    type: 'info'
  },
  10: {
    name: 'Wrong Fileop Code',
    color: 'red',
    type: 'error'
  }
}
export const QUETYPES = {
  CODE: 8
}
export const ANS_JUDGESHOW_MAP = {
  SHOW: 2, // 依赖后台字段conf_submit_type 1整卷（历史默认） 2单题整卷
  NOTSHOW: 1,
  FEED_SHOW: 1, // 显示激励 依赖后台字段conf_answer_feedback 1 yes 2 no
  FEEDBACK_NOTSHOW: 2 // 不显示激励
}
export const AI_JUDGESHOW_MAP = { // 依赖后台字段conf_ai_show 1展示    2不展示
  SHOW: 1,
  NOTSHOW: 2
}
export const SNAPSHOT_MAP = {
  INIT: 0, // 初始值
  SNAP: 1,
  FORMAL: 2
}
export const JUDGE_CODE_MAP = {
  RIGHT: 1,
  ERROR: 0,
  HELF_RIGHT: 2,
  JUDING: -1,
  NO_ANSWER: -2
}
export const JUDGE_CODE = {
  1: 'right', // 正确
  0: 'error', // 错误
  2: 'halfright', // 半对
  '-1': 'juding', // 未判出
  '-2': 'error', // 未作答
  NO_STATUS: -3
}
export const INSPIRE_MAP = {
  PROVINCE_LEVEL_ONE: {
    val: [0.8, 1.1],
    msg: '得分率达到80%，已达省一水平，编程极客追求卓越～',
    smsg: '得分率[80%,100%]:省一',
    weight: 3,
    medal: 1,
    medal_msg: '省一'
  },
  PROVINCE_LEVEL_TWO: {
    val: [0.55, 0.8],
    msg: '得分率达到55%，已达省二水平，大牛就是你啦！',
    smsg: '分率[55%,80%):省二',
    weight: 2,
    medal: 2,
    medal_msg: '省二'
  },
  PROVINCE_LEVEL_THREE: {
    val: [0.3, 0.55],
    msg: '得分率达到30%，已达省三水平，潜力之星加油哦！',
    smsg: '得分率[30%,55%):省三',
    weight: 1,
    medal: 3,
    medal_msg: '省三'
  },
  PROVINCE_LEVEL_NONE: {
    val: [0, 0.3],
    msg: '',
    weight: 0,
    medal: 0,
    medal_msg: ''
  }
}
export const EVENT_NAME = {
  MQTT_MESSAGE: 'mqtt_message'
}
export const SK_TYPEMAP = {
  NOC: 'noc', // 根据赛考后台的type 一致
  GUIZHOU: 'guizhou', // 贵州赛考,
  OCEDU: 'ocedu' // 青科展
}
export const STATE_MAP = {
  sk: 'competition',
  help: 'help',
  privacy: 'privacy',
  statement: 'statement'
}
export const PAPER_STYLE_MODE = {
  SIMPLE: 2,
  DEFAULT_ST: 1
}
export const CAMERA_AUTH_MAP = {
  DEFAULT: -1, // 默认初始值
  OPEN: 1,
  NONE: 0
}
export const MINI_DEVICE_TEST_STATUS = {
  DEFAULT: -1, // 默认初始值
  NORMAL: 1, // 正常
  UN_NORMAL: 0 // 异常
}
export const BISAI_STATE_MAP = { // 考试状态 0未开始 1进行中 2已结束
  NOT_START: 0,
  ING: 1,
  END: 2
}
export const PAPER_SK_TYPE = { // 卷子是模拟卷、正式卷：1模考 2正式
  MOCK: 1,
  NORMAL: 2
}
export const BISAI_INTOSTATE_MAP = { // 卷子可提前进入状态 1可进入 2 不可进入
  ING: 1,
  NO: 2
}
export const EFFECT_TIME = [3600, 1800, 600]
export const SK_HOST = ['codeds.xueersi.com', 'test.ocedu.cn']
export const SECRET_KEY = '-----BEGIN RSA PRIVATE KEY-----\nMIICXAIBAAKBgQDjYhEzqQ4e9yuFGmsHWMsUxXGniNlnuyPqI1/IedBeA10a5phFVI0P2nH9QX+9PCHk0jKP1LbZQyyio3XWejsPUq1yrDYuYlz+ZjErwtVT8HMNOzQaOGoV5ZFHsb4ax3mwdqkRgrc/39lZD+Bh3XZ4/zFY0jz/9RB4+Gtx1aZfPwIDAQABAoGADrpqbNM1c/IEoqF8b0RkGxtS6kwl8N8hS2gxKxYg1gYXwMdQ86SvYmh+262jbYl4lI688a4QjfAY8wvtdRADxTIETS/yQOmgQL+GQ1CX3MgP/gcGFNtw9OtDChFIO/iXp8hqv8L/A61Lf1tIcVqnYg39X4PGPvMyR9YhI81GggECQQD+SPAZZ/MIiHLeSntFE1z2BzrNilhwbGJRTclEHZiRdYjJfTDx4BSmgpmx/onk8Moz7crobQKx+hnB1E8654I/AkEA5Oqt27/jMMBwxLBsagbfSEpJSn99TVbfaJq8tIdn7Yz3m6aHgkKInqSQR+HtAwgFrD2dKXMx0orC0H5LC3/jAQJAWRnNp9lYu4C0X1fR4d5rEHpwDF2Rp7MSsFKFV6zXLQD2MUQAxoAyPDY/DLOYNfR5pcCI/PBVpW9xXw25xXYhmQJAfYhgTQieauatMH9bBI2+NiNeq7bcHORN4UwxvYIfsPpD8pxQ5VLdHro50OGJGsd0bO7g15EvOjkT+ke3mZfJAQJBAJUw6uyEFhAFTKnQn8Nx7RxORyXKi6c7afXNr9kjFbVhGC1FFfb4W0AC8Igo6hyEM1eYwDXu5ixZOeo53R0vo1c=\n-----END RSA PRIVATE KEY-----'
export const DEVICE_TYPES = {
  PC: 1,
  MOBILE: 2
}
export const MINITOR_TYPES = {
  PC: 1,
  MOBILE: 2,
  RECORD: 3
}
export const GRADES = [
  { value: '', txt: '全部' },
  { value: '小学', txt: '小学' },
  { value: '初中', txt: '初中' },
  { value: '高中', txt: '高中' }
]
export const DIFFLANGGRADE = {
  Scratch: [
    { value: '', txt: '全部' },
    { value: '小学', txt: '小学' }
  ],
  Python: [
    { value: '', txt: '全部' },
    { value: '小学', txt: '小学' },
    { value: '初中', txt: '初中' }
  ],
  'C++': [
    { value: '', txt: '全部' },
    { value: '小学', txt: '小学' },
    { value: '初中', txt: '初中' },
    { value: '高中', txt: '高中' }
  ],
  '': GRADES
}
export const DIFFGRADELANG = {
  '': [
    { value: '', txt: '全部' },
    { value: 'Scratch', txt: 'Scratch' },
    { value: 'Python', txt: 'Python' },
    { value: 'C++', txt: 'C++' }
  ],
  0: [
    { value: '', txt: '全部' },
    { value: 'Scratch', txt: 'Scratch' },
    { value: 'Python', txt: 'Python' },
    { value: 'C++', txt: 'C++' }
  ],
  小学: [
    { value: '', txt: '全部' },
    { value: 'Scratch', txt: 'Scratch' },
    { value: 'Python', txt: 'Python' },
    { value: 'C++', txt: 'C++' }
  ],
  初中: [
    { value: '', txt: '全部' },
    { value: 'Python', txt: 'Python' },
    { value: 'C++', txt: 'C++' }
  ],
  高中: [
    { value: '', txt: '全部' },
    { value: 'C++', txt: 'C++' }
  ]
}
export const LANGES = [
  { value: '', txt: '全部' },
  { value: 'Scratch', txt: 'Scratch' },
  { value: 'Python', txt: 'Python' },
  { value: 'C++', txt: 'C++' }
]
export const QKZ_HOSTS = ['qp.enjoycode.com']
