export const mutations = {
  setRankList (state, data) {
    state.rankList = data
  },
  clearAll (state) {
    state.rankList = null
  },
  setProblemList (state, data) {
    state.problemList = data
  }
}
