import { getQpUserInfo, getTemplateIds } from '@/http/qp'

export const actions = {
  /**
   * [getCompetition 获取赛事信息]
   * @return {[Promise]}
   */
  async getQpUser ({ rootState, commit }) {
    const info = await getQpUserInfo()
    commit('setUser', info)
    return info
  },
  async getTemplateIds ({ rootState, commit }) {
    const info = await getTemplateIds()
    const formatInfo = {}
    info.map(item => {
      if (item.lang === 'c++' || item.lang === 'C++') {
        formatInfo.cpp = item.url
      } else {
        formatInfo[item.lang.toLocaleLowerCase()] = item.url
      }
    })
    commit('setTemplateids', formatInfo)
    return info
  }
}
