import Cookie from './cookie'
import { SK_HOST, SECRET_KEY, EFFECT_TIME, QKZ_HOSTS } from '@/common/config'
import JSEncrypt from 'jsencrypt'
export const ua = window.navigator.userAgent.toLowerCase()
export const isWechat = () => {
  return !!/micromessenger/.test(ua)
}
export function objectToGetParams (obj) {
  let result = ''
  for (const key in obj) {
    result += `${key}=${obj[key]}&`
  }
  result = result.substr(0, result.length - 1)
  return result
}
// 处理dataset ie10 兼容问题
export const setDataset = (el, key, value) => {
  if (el.dataset) {
    el.dataset[key] = value
  } else {
    el.setAttribute(`data-${key}`, value)
  }
}

export const getDataset = (el, key, type) => {
  if (el.dataset) {
    return el.dataset[key] || ''
  } else {
    const value = el.getAttribute(`data-${key}`) || ''
    if (type === 'number') { // 只处理整数和字符串的情况
      return parseInt(value)
    } else {
      return value
    }
  }
}
function loadJs (url) {
  return new Promise(resolve => {
    const _doc = document.getElementsByTagName('head')[0]
    const js = document.createElement('script')
    js.setAttribute('type', 'text/javascript')
    js.setAttribute('src', url)
    _doc.appendChild(js)
    const ie = navigator.userAgent.indexOf('MSIE') > -1
    if (!ie) {
      // !ie浏览器
      js.onload = () => {
        resolve()
      }
    } else {
      js.onreadystatechange = function () {
        if (js.readyState === 'loaded' || js.readyState === 'complete') {
          // 异步js加载完毕
          resolve()
        }
      }
    }
  })
}
// 获取在线文件内容
export const getOnlineFile = (url) => {
  return loadJs(url)
}
// 获取url上的一个query - key 值
export function getQueryString (name) {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  const r = window.location.search.substr(1).match(reg)
  if (r !== null) return r[2]
  return ''
}
// 获取url上的query对象
export function param2Obj (url) {
  if (typeof url !== 'undefined') {
    const _url = url.split('?')
    if (_url.length > 1) {
      url = _url[1] // 从起始索引号提取字符串中指定数目的字符
      const arr = url.split('&') // 把字符串分割为字符串数组
      const obj = {}
      let newarr = []
      arr.forEach(v => {
        // 数组遍历
        newarr = v.split('=')
        if (typeof obj[newarr[0]] === 'undefined') {
          obj[newarr[0]] = newarr[1]
        }
      })
      return obj
    }
    return {}
  }
}
export function isAndroid () {
  return !!/android/.test(ua)
}
/**
 * [isTeacherEnd 判断是否为教师端]
 * @return {Boolean}
 */
export function isTeacherEnd () {
  return (ua.indexOf('xescef') === -1 && ua.indexOf('qtwebengine') > -1) || (/(jzh)/i.test(ua) && /pc\/client/i.test(ua))
}
/**
 * [isCorrectEnd 批改端]
 * @return {Boolean}
 */
export function isCorrectEnd () {
  return ua.indexOf('homework_correct_app') > -1 || ua.indexOf('homework') > -1 || ua.indexOf('correct') > -1
}
/**
 * [isStudentEnd 学生端]
 * @return {Boolean}
 */
export function isStudentEnd () {
  return window.XesInstance.isMobileApp() || window.XesInstance.isWdFunction()
}
export function isMobile () {
  const iphone = ua.match(/iphone/gi)
  const ipad = ua.match(/ipad/gi) || (navigator.platform === 'macintel' && navigator.maxTouchPoints > 1)
  const android = ua.match(/android/gi)
  if (window.location.href.indexOf('cw_platform=ios') > -1 || window.location.href.indexOf('cw_platform=android') > -1 || iphone || android || ipad) {
    return true
  }
  return false
}
/**
 * [isChrome chrome 浏览器判定]
 * @return {Boolean}
 */
export function isChrome () {
  return ua.indexOf('chrome') > -1
}
export function guid () {
  const r = (new Date()).getTime().toString(16) +
    Math.random().toString(16).substring(2) + '0'.repeat(16)
  const guid = r.substr(0, 8) + '-' + r.substr(8, 4) + '-4000-8' +
    r.substr(12, 3) + '-' + r.substr(15, 12)
  return guid
}
export function isSelf () {
  const isSk = isSkDomain()
  if (isSk) {
    const skFlag = !(getQueryString('stuId') && getQueryString('stuId') !== Cookie.get('code_sk_user_id')) // 是本人
    return skFlag
  }
  // 返回值是true 是本人 false是非本人
  const flag = !(getQueryString('stuId') && getQueryString('stuId') !== Cookie.get('stu_id')) // 是本人
  return flag || (window.XesInstance.isMobileApp() || window.XesInstance.isWdFunction()) // 是本人或者是学生端 都认为是本人
}

export function getCookieStuId (key = 'stu_id') {
  const isSk = isSkDomain()
  if (isSk) {
    key = 'code_sk_user_id'
  }
  return Cookie.get(key)
}
export function isSkDomain () {
  let isSk = false
  for (let i = 0; i < SK_HOST.length; i++) {
    if (location.host.indexOf(SK_HOST[i]) > -1) {
      isSk = true
    }
  }
  return isSk
}
export function isQpDomain () {
  let isQp = false
  for (let i = 0; i < QKZ_HOSTS.length; i++) {
    if (location.host.indexOf(QKZ_HOSTS[i]) > -1) {
      isQp = true
    }
  }
  return isQp
}
export function encryptMI (origin) {
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(SECRET_KEY)
  return encrypt.encrypt(origin)
}

export function decryptMI (cipher) {
  const cipherNew = JSON.parse(cipher)
  const decArray = []
  const decrypt = new JSEncrypt()
  decrypt.setPrivateKey(SECRET_KEY)
  for (let i = 0; i < cipherNew.length; i++) {
    decArray.push(decrypt.decrypt(cipherNew[i]))
  }
  return decArray.join('')
}
export function effectTimes () {
  return getQueryString('cutdown') ? getQueryString('cutdown').split(',') : EFFECT_TIME
}
export function updateLeftTime (params) {
  // 某个学生的某个赛事某个比赛的某个卷子
  const uid = Cookie.get('code_sk_user_id')
  const key = `left_${uid}_${params.saishi_id}_${params.bisai_id}_${getQueryString('pno')}`
  localStorage.setItem(key, params.left)
}
export function getLeftTime (params) {
  // 某个学生的某个赛事某个比赛的某个卷子
  const uid = Cookie.get('code_sk_user_id')
  const key = `left_${uid}_${params.saishi_id}_${params.bisai_id}_${getQueryString('pno')}`
  return localStorage.getItem(key) ? localStorage.getItem(key) : -1
}
export function delLeftTime (params) {
  const uid = Cookie.get('code_sk_user_id')
  const key = `left_${uid}_${params.saishi_id}_${params.bisai_id}_${getQueryString('pno')}`
  localStorage.removeItem(key)
}
export function sumArray (data) {
  let data_ = null
  let sum = 0
  if (typeof data === 'object') {
    data_ = data
  } else {
    data = String(data)
    data_ = data.split(',')
  }
  for (let i = 0; i < data_.length; i++) {
    sum += data_[i] * 1
  }
  return sum
}
export function getNetType () {
  if (window.navigator.connection) {
    const netType = window.navigator.connection.effectiveType
    if (!window.navigator.onLine) {
      return 'offline'
    }
    return netType
  }
}
