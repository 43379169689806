export const mutations = {
  setCutout (state, data) {
    state.cutout++
  },
  initCount (state, data) {
    state.cutout = data
  },
  clearAll (state) {
    state.cutout = 0
  }
}
