<template>
  <div
    :class="`feedmsg feedmsg-${type}`"
    :style="{
      zIndex: zIndex
    }"
  >
    <div class="feedmsg-icon">
      <div :class="`feedmsg-icon__img-${type}`"></div>
    </div>
    <div class="feedmsg-text">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: 'XesMessage',

  props: {
    // tips的类型
    type: {
      type: [Number, String],
      default: '',
      required: false
    },

    // tips的文字内容
    message: {
      type: String,
      default: '这是一条tips',
      required: false
    },

    // css的层级
    zIndex: {
      type: Number,
      default: 2500,
      required: false
    },

    // 唯一id
    guid: {
      type: String,
      required: true
    },

    // index 当前tips的索引
    index: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      duration: 2500
    }
  },

  mounted () {
    // 3秒后自动关闭
    setTimeout(() => {
      this.$emit('delete', this.guid)
    }, this.duration)
  }
}
</script>

<style lang="less" scoped>
.feedmsg {
  box-sizing: border-box;
  border-radius: 57px;
  width: 1403px;
  height: 114px;
  box-shadow: 0px 8px 17px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: all;
  &-icon {
    margin-left: 6px;
    width: 104px;
    height: 104px;
    background: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    &__img-1 {
      width: 89px;
      height: 77px;
      background-image: url('./icon/top_one.png');
      background-size: 100% 100%;
    }
    &__img-2 {
      width: 89px;
      height: 76px;
      background-image: url('./icon/top_two.png');
      background-size: 100% 100%;
    }
    &__img-3 {
      width: 89px;
      height: 66px;
      background-image: url('./icon/top_three.png');
      background-size: 100% 100%;
    }
  }
  &-text {
    margin-left: 60px;
    font-size: 44px;
    font-family: fy;
    font-weight: 400;
    color: #333;
  }
}
.feedmsg-1 {
  // background-color: #F9D657;
  background: rgba(249, 214, 87, .9);
}
.feedmsg-2 {
  // background-color: #B3DFF8;
  background: rgba(179,223,248, .9);
}
.feedmsg-3 {
  // background-color: #F8B96F;
  background: rgba(248,185,111, .9);
}
</style>
