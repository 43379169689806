export const loggerConfig = {
  cacheUploader: {
    interval: 10000,
    batch: 5
  },
  common: {
    eventid: 'code-paper'
  },
  network: {
    open: true,
    sample: 1
  },
  clickMsg: {
    open: true
  },
  loadMsg: {
    open: false // 自动展现日志开关
  },
  runtime: {
    open: true,
    sample: 1
  },
  resource: {
    open: true,
    sample: 1
  },
  hashchange: {
    open: true // hashchange自动展现日志开关
    // pvid: 'hash',
    // pageuid: { // 展现日志、交互日志pageuid路由映射
    //   index: 'pageIndex', // 首页路由pageuid映射
    //   path: 'path333',
    //   'path/a': 'path/a333'
    // },
    // loadParam: { // 展现日志私有字段路由映射
    //   index: { key1: 'xxx' }, // 首页路由展现日志私有字段映射
    //   path: { key1: 'yyy' },
    //   'path/a': { key1: 'zzz' }
    // }
  },
  performance: {
    open: true,
    sample: 1
  },
  vue: {
    errorHandler: true
  },
  trace: {
    open: false
  },
  appid: {
    'code.xueersi.com': {
      appid: '1005016',
      appkey: 'df34b8f13a398c6f454bb310574b2808'
    },
    'codeds.xueersi.com': {
      appid: '1005016',
      appkey: 'df34b8f13a398c6f454bb310574b2808'
    },
    'test.ocedu.cn': {
      appid: '1005016',
      appkey: 'df34b8f13a398c6f454bb310574b2808'
    },
    'qp.enjoycode.com': {
      appid: '1005016',
      appkey: 'df34b8f13a398c6f454bb310574b2808'
    }
  },
  param: {
    key: '200021' // 展现日志、交互日志公共字段
  }
}
