export const mutations = {
  setPageVisiable (state, data) {
    state.pageVisiable = data
  },
  setLeaveTime (state, data) {
    state.pageLeaveTime = data
  },
  setPraticeInfo (state, data) {
    state.practiceInfo = data
  },
  _updatePracticeInfo (state, data) {
    // 慎用，practiceInfo 一般仅为只读
    // 新增该方法为倒计时更新时间所用
    state.practiceInfo = Object.assign({}, state.practiceInfo, data)
  },
  setUserType (state, data) {
    state.userType = data
  },
  setUserScore (state, data) {
    state.userScore = data
  },
  setUserCourse (state, data) {
    state.userCourse = data
  },
  setPaperTime (state, data) {
    state.paperTime = { ...data }
  },
  updatePaperTime (state, data) {
    state.paperTime = Object.assign({}, state.paperTime, data)
  },
  setPraticeProblemInfo (state, data) {
    state.problems = data
  },
  setCommitStateList (state, data) {
    state.commitStateList = data
  },
  setPaperCommitTime (state, data) {
    state.commit_at_times = data // 试卷的提交时间，时间戳
  },
  setPaperState (state, data) {
    state.paperState = data
  },
  setApiPaperState (state, data) {
    state.apiPaperState = data
  },
  setCommitList (state, data) {
    state.commitList = data
  },
  setCommitId (state, data) {
    state.commitid = data
  },
  setCurrentIndex (state, data) {
    state.currentIndex = data
  },
  setNextPageIndex (state, data) {
    state.nextPageIndex = data
  },
  setCurrentAnswer (state, data) {
    const queId = data.queId !== data.rootId ? data.rootId : data.queId
    if (state.currentAnswer) {
      state.currentAnswer = Object.assign({}, {
        [queId]: {
          ...data,
          userAnswerData: Object.assign({}, state.currentAnswer.userAnswerData, data)
        }
      })
      console.log('----------has exit state.currentAnswer: ', state.currentAnswer)
      return
    }
    state.currentAnswer = Object.assign({}, {
      [queId]: {
        ...data,
        userAnswerData: data
      }
    })
    console.log('---------state.currentAnswer: ', state.currentAnswer)
  },
  removeCurrentAnswers (state, queId) {
    if (state.currentAnswers[queId]) {
      delete state.currentAnswers[queId]
    }
  },
  clearCurrentAnswer (state) {
    state.currentAnswer = null
  },
  setCurrentAnswers (state, data) {
    const queId = data.queId !== data.rootId ? data.rootId : data.queId
    const uans = []
    if (state.currentAnswers[queId]) {
      const answerEdit = Object.assign({}, { [data.queId]: data })
      state.currentAnswers[queId] = Object.assign({}, state.currentAnswers[queId], answerEdit)
      // 更新userAnswerData
      if (data.queId !== data.rootId) {
        // 复合题目
        for (const key in state.currentAnswers[queId]) {
          if (state.currentAnswers[queId][key] && state.currentAnswers[queId][key].queId) {
            uans.push(state.currentAnswers[queId][key])
          }
        }
        state.currentAnswers[queId].userAnswerData = {
          data: uans
        }
      } else {
        // 普通客观题
        state.currentAnswers[queId].userAnswerData = data
      }
      // console.log('----------has exit state.currentAnswers: ', state.currentAnswers)
      return
    }
    const answer = Object.assign({}, { [data.queId]: data })
    state.currentAnswers[queId] = Object.assign({}, state.currentAnswers[queId], answer)
    // 更新userAnswerData
    if (data.queId !== data.rootId) {
      // 复合题目
      for (const key in state.currentAnswers[queId]) {
        if (state.currentAnswers[queId][key] && state.currentAnswers[queId][key].queId) {
          uans.push(state.currentAnswers[queId][key])
        }
      }
      state.currentAnswers[queId].userAnswerData = {
        data: uans
      }
    } else {
      // 普通客观题
      state.currentAnswers[queId].userAnswerData = data
    }
    // console.log('---------state.currentAnswers: ', state.currentAnswers)
  },
  clearCurrentAnswers (state) {
    state.currentAnswers = {}
  },
  clearApiAnswers (state) {
    state.apiAnswers = {}
  },
  clearApiOriginAnswers (state) {
    state.apiAnswersOrigin = {}
  },
  setInitAllAnswers (state, data) {
    // state.currentAnswers = Object.assign({}, state.currentAnswers, data) // 复合题型前代码赋值
    // 支持复合题目结构修改 at 0506
    state.currentAnswers = {}
    for (const key in data) {
      state.currentAnswers[key] = {}
      if (data[key].problem_code) {
        // 编程题目
        state.currentAnswers[key] = Object.assign({}, state.currentAnswers[key], data[key])
      } else {
        // 客观题
        const uans = data[key].userAnswerData && data[key].userAnswerData.data ? data[key].userAnswerData.data : []
        for (let i = 0; i < uans.length; i++) {
          if (typeof uans[i] === 'object') {
            // 复合题
            state.currentAnswers[key][uans[i].queId] = Object.assign({}, {
              queId: uans[i].queId,
              rootId: key,
              data: uans[i].data
            })
          } else {
            // 单题
            state.currentAnswers[key][key] = Object.assign({}, {
              queId: key,
              rootId: key,
              data: uans
            })
          }
        }
        state.currentAnswers[key] = Object.assign({}, state.currentAnswers[key], data[key])
      }
    }
  },
  setInitApiAnswers (state, data) {
    // 支持复合题目结构修改 at 0506
    state.apiAnswers = {}
    for (const key in data) {
      state.apiAnswers[key] = {}
      if (data[key].problem_code) {
        // 编程题目
        state.apiAnswers[key] = Object.assign({}, state.apiAnswers[key], data[key])
      } else {
        // 客观题
        const uans = data[key].userAnswerData.data
        for (let i = 0; i < uans.length; i++) {
          if (typeof uans[i] === 'object') {
            // 复合题
            state.apiAnswers[key][uans[i].queId] = Object.assign({}, {
              queId: uans[i].queId,
              rootId: key,
              data: uans[i].data
            })
          } else {
            // 单题
            state.apiAnswers[key][key] = Object.assign({}, {
              queId: key,
              rootId: key,
              data: uans
            })
          }
        }
        state.apiAnswers[key] = Object.assign({}, state.apiAnswers[key], data[key])
      }
    }
  },
  setInitApiAnswersOrigin (state, data) {
    // 支持复合题目结构修改 at 0506
    state.apiAnswersOrigin = {}
    for (const key in data) {
      state.apiAnswersOrigin[key] = {}
      if (data[key].problem_code) {
        // 编程题目
        state.apiAnswersOrigin[key] = Object.assign({}, state.apiAnswersOrigin[key], data[key])
      } else {
        // 客观题
        const uans = data[key].userAnswerData.data
        for (let i = 0; i < uans.length; i++) {
          if (typeof uans[i] === 'object') {
            // 复合题
            state.apiAnswersOrigin[key][uans[i].queId] = Object.assign({}, {
              queId: uans[i].queId,
              rootId: key,
              data: uans[i].data
            })
          } else {
            // 单题
            state.apiAnswersOrigin[key][key] = Object.assign({}, {
              queId: key,
              rootId: key,
              data: uans
            })
          }
        }
        state.apiAnswersOrigin[key] = Object.assign({}, state.apiAnswersOrigin[key], data[key])
      }
    }
  },
  updateApiAnswers (state, data) {
    state.apiAnswers = Object.assign({}, state.apiAnswers, data)
  },
  addJudgeAnswers (state, data) {
    if (state.currentAnswer && state.currentAnswer[data.queId]) {
      state.currentAnswer = Object.assign({}, {
        [data.queId]: {
          ...data,
          userAnswerData: Object.assign({}, state.currentAnswer.userAnswerData, data)
        }
      })
    }
    if (state.currentAnswers[data.queId]) {
      state.currentAnswers[data.queId] = Object.assign({}, state.currentAnswers[data.queId], {
        ...data,
        userAnswerData: Object.assign({}, state.currentAnswers[data.queId].userAnswerData, data)
      })
    } else {
      state.currentAnswers[data.queId] = Object.assign({}, {
        [data.queId]: {
          ...data
        }
      }, {
        ...data,
        userAnswerData: { ...data }
      })
    }
  },
  setPaperSubmit (state, data) {
    state.paperSubmit = data
  },
  clearUserData (state, index) {
    state.paperState = -1
    state.commit_at_times = 0
    state.commitid = ''
    state.currentAnswer = null
    state.currentAnswers = {}
    state.currentIndex = index || 0
    state.nextPageIndex = 0
    state.paperSubmit = false
  },
  updateAnswersTime (state, data) {
    if (!state.answersTime) {
      state.answersTime = {}
    }
    state.answersTime[data.id] = Object.assign({}, state.answersTime[data.id], data.data)
  },
  clearAll (state) {
    state.practiceInfo = null // 练习||试卷信息
    state.problems = null // 练习||试卷 题目信息
    state.commitStateList = null // 提交历史状态信息
    state.commitList = null // 提交历史信息
    state.paperState = -1 // 练习||试卷 所处状态
    state.commit_at_times = 0 // 提交时间
    state.apiPaperState = -1 // 接口最原始返回的状态
    state.commitid = '' // 新的作答id
    state.currentAnswers = {} // 目前的答题列表情况
    state.apiAnswers = {} // 接口记录中的答题情况
    state.currentAnswer = null // 当前作答题目的答案
    state.currentIndex = 0 // 当前在答的题目索引
    state.nextPageIndex = 0 // 下一个要去的题目索引
    state.paperSubmit = false // 是否需要整卷提交
    state.paperTime = null // 考试时长相关信息
    state.answersTime = null // 学生作答时长
    state.userType = '' // 0 未购课 1 长期 2 短期
    state.userCourse = {} // 用户课程信息
    state.pageVisiable = true // 页面是否可见，默认可见
    state.pageLeaveTime = 0
    state.userId = '' // 用户id
  },
  setUserId (state, data) {
    state.userId = data
  }
}
