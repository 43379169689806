import Vue from 'vue'
import Vuex from 'vuex'
import user from './user' // 用户信息
import codeUp from './codeUp' // 活动信息
import rank from './rank' // 排名信息
import inner from './innerStates'
import feedback from './feedback'
import mqtt from './mqtt' // mqtt 消息服务
import competition from './competition' // 赛考-赛事
import monitor from './monitor' // 监测
import device from './device' // 设备
import qp from  './qp' // 青科展
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user: {
      namespaced: true,
      ...user
    },
    codeUp: {
      namespaced: true,
      ...codeUp
    },
    qp: {
      namespaced: true,
      ...qp
    },
    rank: {
      namespaced: true,
      ...rank
    },
    inner: {
      namespaced: true,
      ...inner
    },
    feedback: {
      namespaced: true,
      ...feedback
    },
    mqtt: {
      namespaced: true,
      ...mqtt
    },
    sk: {
      namespaced: true,
      ...competition
    },
    monitor: {
      namespaced: true,
      ...monitor
    },
    device: {
      namespaced: true,
      ...device
    }
  }
})
