var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"xes-button",class:{
    [`xes-button--${_vm.type}`]: true,
    [`xes-button--full`]: _vm.full,
    [`xes-button--${_vm.size}`]: _vm.size !== '',
    [`xes-button--full-refund`]: _vm.fullRefund,
    [`xes-is-disabled`]: _vm.disabled,
    [`xes-is-disabled--${_vm.type}`]: _vm.disabled,
    [`xes-is-loading`]: _vm.loading,
    [`xes-is-loading--${_vm.type}`]: _vm.loading
  },style:(_vm.styles),attrs:{"type":"button","disabled":_vm.disabled},on:{"click":_vm.handleClick}},[(_vm.loading)?_c('svg',{staticClass:"xes-button-circular",attrs:{"viewBox":"25 25 50 50"}},[_c('circle',{staticClass:"xes-button-path",attrs:{"cx":"50","cy":"50","r":"20","fill":"none"}})]):_vm._e(),_c('span',[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }