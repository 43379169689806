export const mutations = {
  setCompetition (state, data) {
    state.competition = data
  },
  updateDeviceTest (state) {
    state.deviceTest = true
  },
  updateMiniDeviceTest (state) {
    state.miniDeviceTest = true
  },
  setBsDetail (state, data) {
    state.bsDetail = data
  },
  setUSerInfo (state, data) {
    state.userInfo = data
  },
  setUserLang (state, data) {
    state.userInfo.lang = data
  },
  setBsId (state, data) {
    state.bsId = data
  },
  clearDeviceState (state) {
    state.deviceTest = false // 设备检测状态
    state.miniDeviceTest = false // 小程序设备检测状态
  },
  clearAll (state) {
    state.competition = null // 赛事信息
    state.deviceTest = false // 设备检测状态
    state.miniDeviceTest = false // 小程序设备检测状态
    state.bsDetail = null // 比赛详情
    state.bsId = null // 比赛id
  }
}
