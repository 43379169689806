import XesLoggerSDK from '@xes/xes_fe_log'
import { loggerConfig } from './config'
import { objectToGetParams, param2Obj, isSkDomain } from '@/common/utils'
import cookie from '@/common/utils/cookie'

export default class XesLogger {
  constructor (vm) {
    this.pageInfo = {}
    this.logger = new XesLoggerSDK(loggerConfig)
  }

  setPageInfo (pageInfo) {
    this.pageInfo = pageInfo
  }

  sendLog (type, content, elem) {
    let logData = {}
    let cookieUid
    if (isSkDomain()) {
      cookieUid = cookie.get('code_sk_user_id')
    } else {
      cookieUid = cookie.get('stu_id')
    }
    content.userid = content.userid ? content.userid : cookieUid // 加入默认的cookie用户信息
    const query = param2Obj(location.href)
    content.userid = query.stuId ? query.stuId : content.userid
    content = Object.assign(query, content, this.pageInfo) // 用户数据优先级高于query 默认数据
    content.type = type
    logData.elem = elem
    if (type === 'click') {
      // 加入url query 信息
      logData.params = objectToGetParams(content)
      this.logger.clickMsg(logData)
    } else if (type === 'pageLoad') {
      logData = objectToGetParams(content)
      this.logger.loadMsg(logData)
    }
  }
}
