/* eslint-disable */
let _isValidKey = function (key) {
    return (new RegExp('^[^\\x00-\\x20\\x7f\\(\\)<>@,;:\\\\\\"\\[\\]\\?=\\{\\}\\/\\u0080-\\uffff]+\x24')).test(key);
};

// 如果是服务端，第二个参数必传，没有值可以传空 {}，否则会进到 document 报错
let getRaw = function (key) {
    if (_isValidKey(key)) {
        let reg = new RegExp('(^| )' + key + '=([^;]*)(;|\x24)');
        let result = reg.exec(document.cookie);

        if (result) {
            return result[2] || null;
        }
    }

    return null;
};

let get = function (key) {
    let value = getRaw(key);
    if (typeof value === 'string') {
        value = decodeURIComponent(value);
        return value;
    }
    return null;
};

let setRaw = function (key, value, options) {
    if (!_isValidKey(key)) {
        return;
    }

    options = options || {};
    // 计算cookie过期时间
    let expires = options.expires;
    if (typeof options.expires === 'number') {
        expires = new Date();
        expires.setTime(expires.getTime() + options.expires);
    }

    document.cookie =
        key + '=' + value +
        (options.path ? '; path=' + options.path : '') +
        (expires ? '; expires=' + expires.toGMTString() : '') +
        (options.domain ? '; domain=' + options.domain : '') +
        (options.secure ? '; secure' : '');
};

let remove = function (key, options) {
    options.expires = new Date(0);
    setRaw(key, '', options);
};

let set = function (key, value, options) {
    setRaw(key, encodeURIComponent(value), options);
};

let clear = function () {
    // 获取从当前到根所有路径上的 domain 和 path
    const hostAry = _getHostAry();
    const pathAry = _getPathAry();

    var rs = document.cookie.match(new RegExp('([^ ;][^;]*)(?=(=[^;]*)(;|$))', 'gi'));
    // 删除当前域下所有能取到的cookie
    for (var i in rs) {
        const curKey = rs[i];

        hostAry.forEach(domain => {
            pathAry.forEach(path => {
                document.cookie = `${curKey}=;expires=Mon, 26 Jul 1997 05:00:00 GMT;domain=${domain};path=${path}; `;

                // 不指定 domain，每个 path 来一遍
                document.cookie = `${curKey}=;expires=Mon, 26 Jul 1997 05:00:00 GMT;path=${path}; `;
            });
            // 不指定 path，每个 domain 来一遍
            document.cookie = `${curKey}=;expires=Mon, 26 Jul 1997 05:00:00 GMT;domain=${domain}`;
        });
    };
    console.log('清除后的 document.cookie:', document.cookie);
};

/**
 * 获取从当前子域到根域的所有域名（不包括当前子域，因为当前子域的 cookie 只能通过不指定 domain 来清除）
 * 比如当前域是 m.wechat.dev.speiyou.com
 * 函数会返回 ['.speiyou.com', '.dev.speiyou.com', '.wechat.dev.speiyou.com']
 *
 * 注：不存在清除 dev.speiyou.com 的情况因为取不到
 *
 * @return { Array } [所有域]
 */
function _getHostAry() {
    const host = window.location.host;
    const hostAtom = host.split('.');

    let baseHostAtomAry = [];
    let baseHostAry = [];
    hostAtom.reverse().forEach((key, index) => {
        baseHostAtomAry.push(key);
        if (index >= 1) {
            const copy = JSON.parse(JSON.stringify(baseHostAtomAry));
            const curHost = `.${copy.reverse().join('.')}`;
            baseHostAry.push(curHost);
        }
    });

    // 去掉最后一位，清除的时候不设置 domain 就默认去当前 domain
    baseHostAry.pop();
    return baseHostAry;
};

// path 需要精确匹配，包括 /
// 逻辑同 _getHostAry
function _getPathAry() {
    const path = location.pathname;
    const pathAtom = path.split('/');
    pathAtom.shift();

    let basePathAtomAry = [];
    let basePathAry = ['/'];
    pathAtom.forEach(item => {
        basePathAtomAry.push(item);
        const curBasePath = '/' + basePathAtomAry.join('/');
        basePathAry.push(curBasePath);
        basePathAry.push(curBasePath + '/');
    });
    // 去掉最后两位，清除的时候不设置 path 就默认去当前 path
    basePathAry.pop();
    basePathAry.pop();
    return basePathAry;
}
export default {
    get,
    remove,
    set,
    clear
}
/* eslint-enable */
