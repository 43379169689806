import Vue from 'vue'
import VueRouter from 'vue-router'
import Sk from './sk' // 赛考路由
import Qp from './qp' // 青科展路由
Vue.use(VueRouter)
const routes = [
  {
    path: '/practise',
    name: 'codeup-practise',
    component: () => import(/* webpackChunkName: "codeup-practise" */ '@/views/answer.vue')
  },
  {
    path: '/practise/rank',
    name: 'codeup-rank',
    component: () => import(/* webpackChunkName: "codeup-rank" */ '@/components/RankList.vue')
  },
  {
    path: '/noreponse',
    name: 'codeup-noreponse',
    component: () => import(/* webpackChunkName: "codeup-noreponse" */ '@/components/NoneResponse.vue')
  },
  {
    path: '/answers',
    name: 'codeup-answers',
    component: () => import(/* webpackChunkName: "codeup-answers" */ '@/views/records/stu-record.vue')
  },
  ...Qp,
  ...Sk
]

const router = new VueRouter({
  mode: 'history',
  base: '/paper/',
  routes
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    if (location.host.indexOf('code.xueersi.com') > -1) {
      document.title = '学而思编程'
    }
  }
  next()
})
export default router
