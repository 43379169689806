import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import App from './App.vue'
import router from './router'
import store from './store'
import XesUI from './components/XesUI'
import axios from './plugins/axios'
import '@/common/utils/vconsole'
import XesLogger from './common/logger'
import logStat from './directive/stat'
import { jsErrorStat } from './common/businessStats'
if (!window.logger) {
  var logger = new XesLogger()
  window.logger = logger // 下游统一使用全局的logger进行买点
  window.onerror = function (msg, url, line, col, error) {
    const params = {
      errmsg: msg,
      ext: {
        url,
        line,
        col,
        index: 1
      }
    }
    jsErrorStat(params)
  }
  window.addEventListener('unhandledrejection', event => {
    const params = {
      errmsg: JSON.stringify(event),
      ext: {
        index: 2
      }
    }
    jsErrorStat(params)
  })
}
Vue.use(XesUI)
Vue.use(logStat)
sync(store, router)
Vue.config.productionTip = false
Vue.prototype.$axios = axios
Vue.config.errorHandler = function (err, vm, info) {
  const params = {
    errmsg: err,
    ext: {
      index: 3,
      info
    }
  }
  jsErrorStat(params)
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
