export const mutations = {
  setGoldCoins (state, data) {
    if (data && data.gold_result) {
      const goldResult = data.gold_result
      if (Number(goldResult.code) === 0) {
        // 金币发放成功
        state._innerStateMachine.goldcoins = goldResult.data.gold
      }
    }
  },
  setInnerStateMachine (state, data) {
    state._innerStateMachine = Object.assign({}, state._innerStateMachine, data)
  },
  clearAll (state) {
    state._innerStateMachine.answersheet = false // 是否显示answersheet
    state._innerStateMachine.ploading = false // 题目加载loading
    state._innerStateMachine.incompleteQuestion = false // 试题未完全作答提示弹窗开关
    state._innerStateMachine.incomplete = false // 试卷未完成提示弹窗开关
    state._innerStateMachine.complete = false // 试卷题目全部完成提示检查一下弹窗
    state._innerStateMachine.illePaper = false // 试卷是否合法，控制试卷不合法弹窗 confirmModalVisible
    state._innerStateMachine.illePaperClose = true // 试卷是否合法弹窗，是否展示右上角关闭icon
    state._innerStateMachine.illePaperbtns = true // 试卷是否合法弹窗，是否展示按钮操作，默认展示
    state._innerStateMachine.illePaperInfo = '试卷走丢了，请联系管理员老师或秃头~' // 试卷是否合法，控制试卷不合法弹窗 文案
    state._innerStateMachine.isForceSubmit = false // 是否强制提交，默认为false
    state._innerStateMachine.showGoldCoins = false // 是否显示金币弹窗
    state._innerStateMachine.goldcoins = 0 // 金币数量
    state._innerStateMachine.snapshot = 0 // 枚举值: 0-初始值，题卡切题的值；1-快照；2-正式
    state._innerStateMachine.statement = '' // 当前展示的说明类型
    state._innerStateMachine.skStartBtn = false // 赛考阅读说明页面【开始考试】按钮
    state._innerStateMachine.cutdownmsg = '倒计时' // 倒计时文案
    state._innerStateMachine.timeover = false // 时间耗尽弹窗
    state._innerStateMachine.cutout = false // 切屏检测弹窗
    state._innerStateMachine.checkcamera = false // 提醒检测摄像头弹窗
    state._innerStateMachine.devicedetect = false // 设备检测功能弹窗
    state._innerStateMachine.guide = false
  }
}
