import {
  getPracticeInfo,
  getPracticeProblems,
  getTikuPack,
  getPracticeCommitState,
  // getPracticeHistory,
  getPracticeHistoryTeacher,
  startPractice,
  getAnyPracticeCommitDetail,
  submitSoloPractice,
  submitAllPractice,
  sharePaperProblemWork,
  getExamStartTime,
  getTikuTime,
  getUserInfo,
  updateMonitor,
  getPracticeTime
} from '@/http/codeupHttp'
import { getBisaiTime } from '@/http/sk'
import { getQpUserInfo } from '@/http/qp'
import { COMMIT_STATE, PAPER_TYPE, QUETYPES } from '@/common/config/index'
import { ERROR_CODE } from '@/common/config/errorcode'
// import Cookie from '@/common/utils/cookie'
import { isMobile, isSkDomain, getCookieStuId, decryptMI, isQpDomain } from '@/common/utils/index'

/**
 * [_appendTUrl 对url上没有的key补齐url]
 * @param  {[String]} url
 * @param  {[Object]} target {stuId: val}
 * @return {[String]} 返回处理后的appendes
 */
function _appendTUrl (url, target) {
  const appends = []
  for (const key in target) {
    if (url.indexOf(key) < 0) {
      if (target[key]) {
        // 有值
        appends.push(`${key}=${target[key]}`)
      }
    }
  }
  return appends.length ? appends.join('&') : ''
}
function _getRecordTimekey (data) {
  const { pno, stuId, liveId, practice_commit_id, queId } = data
  return `${stuId || ''}-${liveId || ''}-${pno || ''}-${practice_commit_id || ''}-${queId}`
}
function _getJudgeCode (data) {
  let judgeCode = data.judge.every((item) => Number(item) === 1)
  judgeCode = judgeCode ? Number(judgeCode) : data.judge.length === 1 ? data.judge[0] : 0
  return judgeCode
}
export const actions = {
  /**
   * [getPracticeInfo 获取试卷｜｜练习信息]
   * @return {[Promise]}
   */
  async getPracticeInfo ({ rootState, dispatch, commit }) {
    const { pno, stuId, liveId, planId } = rootState.route.query
    try {
      const practice = await getPracticeInfo({
        practice_code: pno,
        liveId: liveId || planId || '',
        stuId: stuId || ''
      })
      commit('setPraticeInfo', practice)
      commit('setPaperTime', practice)
      return practice
    } catch (err) {
      if (err && err.response) {
        const status = Number(err.response.status)
        if (status === 400) {
          const data = err.response.data
          if (data && Number(data.code) === ERROR_CODE.SK_NOT_LOGIN) {
            commit('_updatePracticeInfo', {
              saishi_channel: data.message
            })
            dispatch('user/login', null, {
              root: true
            })
            return
          }
          commit('inner/setInnerStateMachine', {
            ploading: false,
            illePaper: true,
            illePaperClose: false,
            illePaperbtns: false,
            illePaperInfo: isSkDomain() ? '系统访问异常<br/>请返回赛事官方网站重新进入比赛平台' : err.response.data.message
          }, {
            root: true
          })
        }
      }
      return Promise.reject(err)
    }
  },
  async getUserId ({ rootState, commit }) {
    let data
    if (isQpDomain()) {
      data = await getQpUserInfo()
    } else {
      data = await getUserInfo()
    }
    commit('setUserId', data.user_id)
  },
  async getUserInfo ({ rootState, commit }) {
    if (isQpDomain()) {
      return
    }
    const { course_status, stuId } = rootState.route.query
    const data = await getTikuPack({
      course_status: course_status || '',
      stu_id: stuId || ''
    })
    commit('setUserType', data.user_type)
    commit('setUserCourse', data.top)
  },
  /**
   * [getPracticeProblemInfo 获取试卷｜｜练习题目信息]
   * @return {[Promise]}
   */
  async getPracticeProblemInfo ({ rootState, commit }) {
    const { pno, stuId, liveId, planId } = rootState.route.query
    const { problems } = await getPracticeProblems({
      practice_code: pno,
      liveId: liveId || planId || '',
      stuId: stuId || ''
    })
    const problems_ = []
    let problem
    for (let i = 0; i < problems.length; i++) {
      problem = problems[i]
      if (problem.answer) {
        let answer = JSON.stringify(decryptMI(problem.answer))
        if (location.host !== 'code.xueersi.com') {
          answer = answer.replace(/code.xueersi.com/g, `${location.host}`)
        }
        problem.answer = JSON.parse(JSON.parse(answer))
      }
      for (let i = 0; i < problem.childList.length; i++) {
        const child = problem.childList[i]
        if (child.answer) {
          let answer = JSON.stringify(decryptMI(child.answer))
          if (location.host !== 'code.xueersi.com') {
            answer = answer.replace(/code.xueersi.com/g, `${location.host}`)
          }
          child.answer = JSON.parse(JSON.parse(answer))
        }
      }
      problems_.push(problem)
    }
    commit('setPraticeProblemInfo', problems_)
    return problems
  },
  /**
   * [getCommitList 获取试卷｜｜练习历史提交状态信息]
   * @return {[Promise]}
   */
  async getCommitStateList ({ rootState, state, commit, dispatch }, type) {
    const { pno, stuId, liveId, planId, practice_commit_id } = rootState.route.query
    const data = await getPracticeCommitState({
      practice_code: pno,
      liveId: liveId || planId || '',
      stuId: stuId || ''
    })
    commit('setCommitStateList', data)
    const comitlength = data.length
    if (comitlength > 0) {
      if (practice_commit_id) {
        // 获取对应的提交状态
        const commitState = data.find(item => {
          return Number(item.practice_commit_id) === Number(practice_commit_id)
        })
        commit('setPaperState', commitState.state)
        commit('setApiPaperState', commitState.state)
      } else {
        commit('setPaperState', data[comitlength - 1].state)
        commit('setApiPaperState', data[comitlength - 1].state)
      }
      // 有提交记录
      await dispatch('getPracticeCommitDetail', data[comitlength - 1].practice_commit_id)
      if (data[comitlength - 1].state === COMMIT_STATE.NOT_COMPLETE || data[comitlength - 1].state === COMMIT_STATE.CORRECTED) {
        commit('setCommitId', data[comitlength - 1].practice_commit_id)
      }
    } else {
      commit('setPaperState', COMMIT_STATE.NOT_START)
    }
    return data
  },
  /**
   * [getCommitList 获取试卷｜｜练习历史提交列表信息]
   * @return {[Promise]}
   */
  async getCommitList ({ rootState, state, commit }) {
    const { pno, stuId, liveId, planId, practice_commit_id } = rootState.route.query
    const data = await getPracticeHistoryTeacher({
      practice_code: pno,
      liveId: liveId || planId || '',
      stuId: stuId || ''
    })
    // 兼容材料题目，将judge_detail内字体融合进入commitlist
    const dataCommit = JSON.parse(JSON.stringify(data))
    // for (let i = 0; i < data.length; i++) {
    //   dataCommit.push({
    //     ...data[i]
    //   })
    // }
    let commitpro = '' // 一道单题
    for (let i = 0; i < dataCommit.length; i++) {
      commitpro = {
        ...dataCommit[i]
      }
      let judge = ''
      for (const key in commitpro.ques) {
        if (!commitpro.ques[key].problem_code) { // 仅编程题目有problem_code
          judge = commitpro.ques[key].judge_detail ? JSON.parse(commitpro.ques[key].judge_detail) : null
          const childlist = judge && judge.data ? judge.data : []
          for (let j = 0; j < childlist.length; j++) {
            if (!commitpro.ques[childlist[j].queId]) {
              commitpro.ques[childlist[j].queId] = {
                ...childlist[j],
                is_right: _getJudgeCode(childlist[j])
              }
            }
          }
        }
      }
    }
    commit('setCommitList', dataCommit)
    if (data.length) {
      if (data[0].score) {
        commit('setUserScore', data[0].score)
      }
      let ques = data[0].ques ? data[0].ques : {}
      if (practice_commit_id) {
        // 需要找到与链接上匹配的一次提交答题详情
        for (let i = 0; i < data.length; i++) {
          if (Number(data[i].practice_commit_id) === Number(practice_commit_id)) {
            ques = data[i].ques ? data[i].ques : {}
            break
          }
        }
      }
      for (const key in ques) {
        ques[key].userAnswerData = ques[key].user_answer ? { data: JSON.parse(ques[key].user_answer) } : null
        ques[key].userJudgeDetail = ques[key].judge_detail ? { data: JSON.parse(ques[key].judge_detail) } : null
        if (ques[key].userAnswerData) {
          if (ques[key].userAnswerData.data.answerURL) {
            // ques[key].userAnswerData.data.answerURL = `${ques[key].userAnswerData.data.answerURL}&stuId=${stuId}`
            const append = _appendTUrl(ques[key].userAnswerData.data.answerURL, {
              stuId: stuId,
              liveId: liveId,
              practice_type: state.practiceInfo ? state.practiceInfo.practice_type : ''
            })
            ques[key].userAnswerData.data.answerURL = append ? `${ques[key].userAnswerData.data.answerURL}&${append}` : ques[key].userAnswerData.data.answerURL
          }
        }
      }
      commit('setInitAllAnswers', ques)
    }
    return data
  },
  /**
   * [teacherGetCommitList 获取试卷｜｜练习历史提交列表信息]
   * @return {[Promise]}
   */
  async teacherGetCommitList ({ rootState, state, commit }) {
    const { pno, stuId, liveId, planId, practice_commit_id } = rootState.route.query
    const data = await getPracticeHistoryTeacher({
      practice_code: pno,
      liveId: liveId || planId || '',
      stuId: stuId || ''
    })

    // 兼容材料题目，将judge_detail内字体融合进入commitlist
    const dataCommit = JSON.parse(JSON.stringify(data))
    // for (let i = 0; i < data.length; i++) {
    //   dataCommit.push({
    //     ...data[i]
    //   })
    // }
    let commitpro = '' // 一道单题
    for (let i = 0; i < dataCommit.length; i++) {
      commitpro = {
        ...dataCommit[i]
      }
      let judge = ''
      for (const key in commitpro.ques) {
        if (!commitpro.ques[key].problem_code) { // 仅编程题目有problem_code
          judge = commitpro.ques[key].judge_detail ? JSON.parse(commitpro.ques[key].judge_detail) : null
          const childlist = judge && judge.data ? judge.data : []
          for (let j = 0; j < childlist.length; j++) {
            if (!commitpro.ques[childlist[j].queId]) {
              commitpro.ques[childlist[j].queId] = {
                ...childlist[j],
                is_right: _getJudgeCode(childlist[j])
              }
            }
          }
        }
      }
    }
    commit('setCommitList', dataCommit)
    if (data.length) {
      if (data[0].score) {
        commit('setUserScore', data[0].score)
      }
      let ques = data[0].ques ? data[0].ques : {}
      if (practice_commit_id) {
        // 需要找到与链接上匹配的一次提交答题详情
        for (let i = 0; i < data.length; i++) {
          if (Number(data[i].practice_commit_id) === Number(practice_commit_id)) {
            ques = data[i].ques ? data[i].ques : {}
            break
          }
        }
      }
      for (const key in ques) {
        ques[key].userAnswerData = ques[key].user_answer ? { data: JSON.parse(ques[key].user_answer) } : null
        ques[key].userJudgeDetail = ques[key].judge_detail ? { data: JSON.parse(ques[key].judge_detail) } : null
        if (ques[key].userAnswerData) {
          if (ques[key].userAnswerData.data.answerURL) {
            // ques[key].userAnswerData.data.answerURL = `${ques[key].userAnswerData.data.answerURL}&stuId=${stuId}`
            const append = _appendTUrl(ques[key].userAnswerData.data.answerURL, {
              stuId: stuId,
              liveId: liveId,
              practice_type: state.practiceInfo ? state.practiceInfo.practice_type : ''
            })
            ques[key].userAnswerData.data.answerURL = append ? `${ques[key].userAnswerData.data.answerURL}&${append}` : ques[key].userAnswerData.data.answerURL
          }
        }
      }
      commit('setInitAllAnswers', ques)
    }
    return data
  },
  /**
   * [getNewCommitId 获取新的提交id]
   * @return {[Promise]}
   */
  async getNewCommitId ({ rootState, commit }, params = {}) {
    const { pno, stuId, liveId, planId } = rootState.route.query
    const liveid = liveId || planId
    let data = null
    try {
      data = await startPractice({
        practice_code: pno,
        liveId: liveid,
        stuId: stuId || '',
        ...params
      })
    } catch (err) {
      const status = Number(err.response.status)
      if (status === 400) {
        window.location.reload()
      }
      return Promise.reject(err)
    }
    commit('setCommitId', data.practice_commit_id)
    return data.practice_commit_id
  },
  /**
   * [getAnyPracticeCommitDetail 获取某一次提交的详细信息]
   * @return {[Promise]}
   */
  async getPracticeCommitDetail ({ rootState, state, commit }, id) {
    const { stuId, liveId, planId, practice_commit_id } = rootState.route.query
    const data = await getAnyPracticeCommitDetail({
      practice_commit_id: practice_commit_id || id,
      liveId: liveId || planId || '',
      stuId: stuId || ''
    })
    commit('setPaperCommitTime', data.commit_at_times)
    if (practice_commit_id) {
      // 通过链接查询某一次提交，需要用该次提交状态覆盖掉最后一次，主要信息是页面状态和commitid
      commit('setPaperState', data.state)
      if (data.state === COMMIT_STATE.NOT_COMPLETE) {
        commit('setCommitId', practice_commit_id)
      }
    }
    const ques = data.ques ? data.ques : {}
    for (const key in ques) {
      ques[key].userAnswerData = ques[key].user_answer ? { data: JSON.parse(ques[key].user_answer) } : null
      ques[key].userData = ques[key].user_data ? JSON.parse(ques[key].user_data) : {}
      if (ques[key].userData.snapshot !== ques[key].snapshot) {
        ques[key].userData.snapshot = ques[key].snapshot
      }
      if (ques[key].userAnswerData) {
        if (ques[key].userAnswerData.data.answerURL) {
          // ques[key].userAnswerData.data.answerURL = `${ques[key].userAnswerData.data.answerURL}&stuId=${stuId}`
          const append = _appendTUrl(ques[key].userAnswerData.data.answerURL, {
            stuId: stuId,
            liveId: liveId,
            practice_type: state.practiceInfo.practice_type
          })
          ques[key].userAnswerData.data.answerURL = append ? `${ques[key].userAnswerData.data.answerURL}&${append}` : ques[key].userAnswerData.data.answerURL
        }
      }
    }
    commit('setInitAllAnswers', ques)
    commit('setInitApiAnswers', ques)
    commit('setInitApiAnswersOrigin', ques)
    return data
  },
  /**
   * [practiceProblemSubmit 单题提交]
   * @return {[Promise]}
   */
  async practiceProblemSubmit ({ rootState, state, commit, dispatch }) {
    const { pno, stuId, liveId, planId, practice_commit_id } = rootState.route.query
    const queId = state.problems[state.currentIndex].queId
    const cookieUid = isSkDomain() ? getCookieStuId('code_sk_user_id') : getCookieStuId('stu_id')
    const uidKey = stuId || cookieUid || ''
    const lidKey = liveId || planId || ''
    dispatch('updateAnswersTime', {
      end: new Date().getTime()
    })
    const timeId = _getRecordTimekey({
      stuId: uidKey,
      liveId: lidKey,
      pno,
      practice_commit_id,
      queId
    })
    const params = {
      practice_commit_id: state.commitid,
      que_id: queId,
      problem_code: state.currentAnswers[queId].problem_code || '',
      is_right: state.currentAnswers[queId].is_right,
      judge_detail: JSON.stringify({
        data: state.currentAnswers[queId].judge,
        is_right: state.currentAnswers[queId].is_right
      }),
      liveId: liveId || planId || '',
      stuId: stuId || '',
      user_data: JSON.stringify({
        snapshot: rootState.inner._innerStateMachine.snapshot, // snapshot 1答题卡-临时快照数据    2 提交答案-主动提交数据
        answer_st: state.answersTime[timeId].start,
        answer_end: state.answersTime[timeId].end,
        answer_time: state.answersTime[timeId].end - state.answersTime[timeId].start
      })
    }
    if (Number(state.problems[state.currentIndex].logicQuesTypeId) !== QUETYPES.CODE) {
      let userAnswer = {
        data: []
      }
      if (state.currentAnswers[queId] && state.currentAnswers[queId].userAnswerData && state.currentAnswers[queId].userAnswerData.data) {
        userAnswer = state.currentAnswers[queId].userAnswerData
      }
      // params.user_answer = state.currentAnswers[queId].userAnswerData.data ? JSON.stringify(state.currentAnswers[queId].userAnswerData.data) : ''
      params.user_answer = JSON.stringify(userAnswer.data)
    } else {
      params.user_answer = state.currentAnswers[queId].user_answer
      params.need_judge = state.currentAnswers[queId].need_judge
    }
    const data = await submitSoloPractice(params)
    let judgeAnswers = {
      queId: params.que_id,
      userData: {
        snapshot: rootState.inner._innerStateMachine.snapshot, // snapshot 1答题卡-临时快照数据    2 提交答案-主动提交数据
        answer_st: state.answersTime[timeId].start,
        answer_end: state.answersTime[timeId].end,
        answer_time: state.answersTime[timeId].end - state.answersTime[timeId].start
      }
    }
    if (Number(state.problems[state.currentIndex].logicQuesTypeId) !== QUETYPES.CODE) {
      judgeAnswers = Object.assign({}, judgeAnswers, {
        is_right: params.is_right,
        score: data.data.score
      })
      // 客观题维护分数
    }
    commit('addJudgeAnswers', judgeAnswers)
    // 更新接口历史答题数据
    commit('updateApiAnswers', {
      [params.que_id]: state.currentAnswers[params.que_id]
    })
    return data
  },
  /**
   * [practiceSubmit 整个试卷提交]
   * @return {[Promise]}
   */
  async practiceSubmit ({ rootState, state, dispatch, commit }, params) {
    const { liveId, planId } = rootState.route.query
    const commitParams = Object.assign({}, {
      practice_commit_id: state.commitid,
      liveId: liveId || planId
    }, rootState.route.query, params)
    const data = await submitAllPractice(commitParams)
    // 试卷提交后获取的金币数量
    commit('inner/setGoldCoins', data, {
      root: true
    })
    // 提交试卷后，清空当前commitid
    commit('setCommitId', '')
    // 改变当前的页面状态
    if (state.practiceInfo.practice_type === PAPER_TYPE.HOMEWORK) {
      // 当前practiceInfo一定有值，随堂练习，提交整卷状态直接到【待批改】
      // commit('setPaperState', COMMIT_STATE.NOT_CORRECTED)
      // location.reload()
      if (window.parent.enterFullScreen) {
        // 页面是嵌套在随堂练习course页面里面的
        window.parent.exitFullScreen()
        location.reload()
      } else {
        let route = '/course/submittestsuccess'
        if (isMobile()) {
          route = '/course/padwebkit/submittestsuccess'
        }
        location.replace(`${location.protocol}//${location.host}${route}${location.search}`)
      }
    } else {
      commit('setPaperState', COMMIT_STATE.COMPLETE)
    }
    // 归零整卷提交状态
    commit('setPaperSubmit', false)
    // 赛考中增加监控信息上报
    if (state.practiceInfo.practice_type === PAPER_TYPE.SK) {
      dispatch('updateMonitor')
    }
    return data
  },
  updateMonitor ({ state }) {
    updateMonitor({
      action: 'submit',
      bisai_id: state.practiceInfo.bisai_id,
      bisai_type: state.practiceInfo.bisai_type
    })
  },
  addJudgeAnswers ({ rootState, state, commit }, data) {
    const { stuId, liveId } = rootState.route.query
    let judge = {
      ...data,
      is_right: data.judgeCode,
      queId: data.queId,
      userData: state.currentAnswers[data.queId] ? Object.assign({}, state.currentAnswers[data.queId].userData, {
        snapshot: rootState.inner._innerStateMachine.snapshot
      }) : {
        snapshot: rootState.inner._innerStateMachine.snapshot
      }
    }
    console.log(111, data)
    if (data.judge && data.judge[0] && data.judge[0].answerURL) {
      // 是编程题目，拼接参数处理
      const append = _appendTUrl(data.judge[0].answerURL, {
        stuId: stuId,
        liveId: liveId,
        practice_type: state.practiceInfo.practice_type
      })
      const answerURL = append ? `${data.judge[0].answerURL}&${append}` : data.judge[0].answerURL
      judge = {
        ...judge,
        ...data.judge[0],
        answerURL
      }
    }
    commit('addJudgeAnswers', judge)
  },
  async setShareProject ({ rootState, state, commit }, data) {
    const { pno, liveId, planId } = rootState.route.query
    const queId = state.problems[state.currentIndex].queId
    const postData = {
      practice_code: pno,
      live_id: liveId || planId || '',
      que_id: queId,
      xml: data.xml,
      assets: data.assets,
      userid: data.userid
    }
    const res = await sharePaperProblemWork(postData)
    return res
  },
  /**
   * [fetchExamStartTime 获取考试开始时间信息]
   * @return {[Promise]}
   */
  async fetchExamStartTime ({ rootState, commit }) {
    const { pno, stuId, liveId, planId } = rootState.route.query
    const examTime = await getExamStartTime({
      practice_code: pno,
      liveId: liveId || planId || '',
      stuId: stuId || ''
    })
    commit('setPaperTime', examTime)
    return examTime
  },
  updateAnswersTime ({ rootState, state, commit }, data) {
    const { pno, stuId, liveId, planId, practice_commit_id } = rootState.route.query
    const cookieUid = isSkDomain() ? getCookieStuId('code_sk_user_id') : getCookieStuId('stu_id')
    const uidKey = stuId || cookieUid || ''
    const lidKey = liveId || planId || ''
    const queId = state.problems[state.currentIndex].queId
    const id = _getRecordTimekey({
      stuId: uidKey,
      liveId: lidKey,
      pno,
      practice_commit_id,
      queId
    })
    const params = {
      id: id,
      data: {
        ...data
      }
    }
    commit('updateAnswersTime', params)
  },
  /**
   * 更新赛考时间
   */
  async updateSkTime ({ rootState, state, commit }) {
    if (!state.practiceInfo.bisai_id) {
      return
    }
    const res = await getBisaiTime({
      bi_sai_id: state.practiceInfo.bisai_id,
      bi_sai_type: state.practiceInfo.bisai_type
    })
    commit('_updatePracticeInfo', res)
    return res
  },
  /**
   * 更新比赛时间
   */
  async updatePaperTime ({ rootState, state, commit }) {
    if (!state.practiceInfo.practice_code) {
      return
    }
    const res = await getTikuTime({
      practice_code: state.practiceInfo.practice_code
    })
    commit('updatePaperTime', res)
  },
  /**
   * 更新试卷时间
   */
  async updatePracticeTime ({ rootState, state, commit }) {
    if (!state.practiceInfo.practice_code) {
      return
    }
    const { liveId } = rootState.route.query
    const res = await getPracticeTime({
      practice_code: state.practiceInfo.practice_code,
      liveId
    })
    commit('updatePaperTime', res)
  }
}
