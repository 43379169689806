// 赛考路由
export const codeupRouter = [
  {
    path: '/sk',
    name: 'codeup-sk',
    component: () => import(/* webpackChunkName: "codeup-sk" */ '@/views/sk/index.vue'),
    children: [
      {
        path: ':channel',
        name: 'sk-channel',
        component: () => import(/* webpackChunkName: "sk-channel" */ '@/views/sk/channel.vue'),
        meta: {
          title: '赛考系统'
        }
      },
      {
        path: ':channel/statement',
        name: 'sk-statement',
        component: () => import(/* webpackChunkName: "sk-statement" */ '@/components/Sk/Statements/index.vue'),
        meta: {
          title: '赛考系统'
        }
      },
      {
        path: ':channel/entry',
        name: 'sk-entry',
        component: () => import(/* webpackChunkName: "sk-entry" */ '@/views/sk/entry.vue'),
        meta: {
          title: '赛考系统'
        }
      },
      {
        path: ':channel/detection',
        name: 'detection',
        component: () => import(/* webpackChunkName: "detection" */ '@/components/Sk/DeviceDetection/index.vue'),
        meta: {
          title: '赛考系统'
        }
      }
    ]
  }
]

export default codeupRouter
