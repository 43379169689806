import { getMqttAuth, getSecondMqttAuth } from '@/http/codeupHttp'
import { getCookieStuId, isSkDomain } from '@/common/utils'
import MqttManager from '@/common/mqtt'
export const actions = {
  async getMqttHandler ({ commit }) {
    const { clientid, endpoint, topics, username, password } = await getMqttAuth({
      scene: 'codeup'
    })
    const mqttAuth = {
      url: `wss://${endpoint}`,
      clientid,
      topics,
      username,
      password
    }
    const mqtt = new MqttManager(mqttAuth)
    mqtt.open()
    commit('setMqttHandler', mqtt)
    return mqtt
  },
  async getMonitorMqttHandler ({ commit }) {
    const { clientid, endpoint, topics, username, password } = await getMqttAuth({
      scene: 'saikao'
    })
    const mqttAuth = {
      url: `wss://${endpoint}`,
      clientid,
      topics,
      username,
      password
    }
    const mqtt = new MqttManager(mqttAuth)
    return mqtt.open().then(() => {
      commit('setMonitorMqttHandler', mqtt)
      return mqtt
    })
  },
  async getPublicMonitorMqttHandler ({ commit }) {
    const { clientid, endpoint, topics, username, password } = await getSecondMqttAuth({
      scene: isSkDomain() ? 'saikao' : 'codeUp',
      stuId: getCookieStuId()
    })
    const mqttAuth = {
      url: `wss://${endpoint}`,
      clientid,
      topics,
      username,
      password
    }
    const mqtt = new MqttManager(mqttAuth)
    return mqtt.open().then(() => {
      commit('setMonitorMqttHandler', mqtt)
      return mqtt
    })
  }
}
