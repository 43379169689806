import { LOGOUT_URL, LOGIN_URL } from '@/common/config'
import { doLoginSk, doLoginXxh, doLogoutSk } from '@/http/sk'
import { isQpDomain } from '@/common/utils/index'
function removeUrlKey (url, key) {
  // 创建正则表达式，用于匹配指定的键
  const regex = new RegExp('[?&]' + key + '=[^&]+')
  return url.replace(regex, '')
}
export const actions = {
  login ({ rootState, state }, cburl = location.href) {
    cburl = cburl || location.href
    cburl = removeUrlKey(cburl, 'token')
    const redirectUrl = encodeURIComponent(cburl)
    const practice_channel = rootState.codeUp.practiceInfo && rootState.codeUp.practiceInfo.saishi_channel
    const sk_channel = rootState.sk.competition && rootState.sk.competition.saishi_channel
    const channel = practice_channel || sk_channel
    if (channel) {
      // 赛考登录
      const url = `${location.protocol}//${location.host}/sk/${channel}?redirect_url=${redirectUrl}`
      location.replace(url)
      return
    }
    // 青科展登录
    if (isQpDomain()) {
      location.replace(`${location.protocol}//${location.host}/paper/qp/login?redirect_url=${redirectUrl}`)
      return
    }
    // 网校登录
    // 未登录的，构建redirectUrl跳转网校登录
    const loginUrl = encodeURIComponent(`${LOGIN_URL}${encodeURIComponent(redirectUrl)}`)
    // 未登录的先登出，再登录
    const LOGINOUT = `${LOGOUT_URL}${encodeURIComponent(loginUrl)}`
    location.replace(LOGINOUT)
  },
  async doLoginSk ({ rootState }, data) {
    const { channel } = rootState.route.params
    const { saishi_id, realname, identify_number, phone, verify_code, password } = data
    const userInfo = await doLoginSk({
      channel,
      saishi_id,
      realname,
      identify_number,
      phone,
      verify_code,
      password
    })
    return userInfo
  },
  async doLogoutSk ({ rootState }) {
    doLogoutSk().then(res => {
      const href = location.href
      const lastSlashIndex = href.lastIndexOf('/')
      const newHref = href.slice(0, lastSlashIndex)
      location.replace(newHref)
    })
  },
  async doXxLoginSk ({ rootState }, data) {
    const { channel } = rootState.route.params
    // const { saishi_id, realname, identify_number, phone, verify_code } = data
    const { saishi_id, signature, realName, userId, examCode, timestamp, apiKey, area } = data
    const userInfo = await doLoginXxh({
      signature,
      realName,
      userId,
      examCode,
      timestamp,
      apiKey,
      area,
      // channel,
      saishi_id,
      // realname,
      // identify_number,
      // phone,
      // verify_code
    })
    return userInfo
  }
}
