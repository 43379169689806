export const mutations = {
  // 更新tab选中状态
  changeTab (state, tab) {
    state.selectedType = tab.type
    for (const key in state.tabs) {
      state.tabs[key].active = false
      if (Number(state.tabs[key].type) === Number(tab.type)) {
        state.tabs[key].active = true
      }
    }
  },
  // 更新tab checked状态
  changeTabCheckStatus (state, type) {
    state.tabs[type].checked = true // 该type checked状态改为true
  },
  // 更新设备检测弹窗中的tab
  // type: pc || mini
  updateMiniDeviceTabs (state, type) {
    state.selectedType = state.tabs[type].type
    for (const key in state.tabs) {
      state.tabs[key].show = false
      state.tabs[key].active = false
      if (key === type) {
        state.tabs[key].show = true
        state.tabs[key].active = true
      }
    }
  },
  // 更新试卷中，第二机位 正常或者异常状态
  updateMiniDeviceTestStatus (state, status) {
    state.miniDeviceStatus = status
  },
  clearAll (state) {
    state.tabs = {
      pc: {
        name: '电脑检测',
        type: 1,
        show: true, // 是否显示
        active: true, // 是否选中
        checked: false // 是否完成检测
      },
      mini: {
        name: '手机检测',
        type: 2,
        show: true,
        active: false,
        checked: false
      }
    }
    state.selectedType = 1 // 默认选中电脑检测
  }
}
