export const mutations = {
  setMqttHandler (state, data) {
    state.mqttHandler = data
  },
  setMonitorMqttHandler (state, data) {
    state.monitorHandler = data
  },
  clearAll (state) {
    state.mqttHandler = null // mqtt 判题实例
    state.monitorHandler = null // 监控 实例
  }
}
