import { MINI_DEVICE_TEST_STATUS, DEVICE_TYPES } from '@/common/config'
export const state = {
  tabs: {
    pc: {
      name: '电脑检测',
      type: DEVICE_TYPES.PC,
      show: true, // 是否显示
      active: true, // 是否选中
      checked: false // 是否完成检测
    },
    mini: {
      name: '手机检测',
      type: DEVICE_TYPES.MOBILE,
      show: true,
      active: false,
      checked: false
    }
  },
  selectedType: DEVICE_TYPES.PC, // 默认选中电脑检测
  miniDeviceStatus: MINI_DEVICE_TEST_STATUS.DEFAULT // 手机检测状态 0:默认 1:正常状态 2:异常状态
}
