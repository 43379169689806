// 赛考api
import axios from '@/plugins/axios'
const base = '/api'
// 获取赛事信息
export function getCompetitionInfo (params) {
  return axios.get(`${base}/sai_shi/channel`, {
    params: params
  })
    .then(res => res.data)
}
// 登录
export function doLoginSk (params) {
  return axios.post(`${base}/user/login`, {
    ...params
  }).then(res => res.data)
}
// 退出登录
export function doLogoutSk (params) {
  return axios.post(`${base}/user/logout`, {
    ...params
  }).then(res => res.data)
}
// 信息学会登录
export function doLoginXxh (params) {
  return axios.post(`${base}/user/sso_login`, {
    ...params
  }).then(res => res.data)
}
// 获取验证码
export function getCode (params) {
  return axios.post(`${base}/user/send_verify_code`, {
    ...params
  }).then(res => res.data)
}
// 更新设备检测信息
export function updateDeviceTest (params) {
  return axios.post(`${base}/device_test`, {
    ...params
  }).then(res => res.data)
}
// 获取比赛列表
export function getAllBisai (params) {
  return axios.get(`${base}/bi_sai/list`, {
    params: {
      ...params
    }
  }).then(res => res.data)
}
// 新的获取比赛列表
export function getAllBisaiNew (params) {
  return axios.get(`${base}/bi_sai/list_new`, {
    params: {
      ...params
    }
  }).then(res => res.data)
}
// 获取单个比赛详情
export function getBisaiDetail (params) {
  return axios.get(`${base}/bi_sai/info`, {
    params: {
      ...params
    }
  }).then(res => res.data)
}
// 获取用户报名信息  之前userInfo跟获取单个比赛拆分开
export function getUserInfo (params) {
  return axios.get(`${base}/sai_shi/get_user_info`, {
    params: {
      ...params
    }
  }).then(res => res.data)
}
// 更新监控信息
export function updateMonitor (params) {
  return axios.post(`${base}/monitor  `, {
    ...params
  }).then(res => res.data)
}
// 获取小程序二维码
export function getQrcode (params) {
  return axios.get(`${base}/wxa_code`, {
    params: {
      ...params
    }
  }).then(res => res.data)
}

// 查看最近时间内 是否有监考图片信息
export function hasMonitorInfo (params) {
  return axios.post(`${base}/check_monitor`, {
    ...params
  }).then(res => res.data)
}
// 获取比赛时间
export function getBisaiTime (params) {
  return axios.get(`${base}/bi_sai/time`, {
    params: {
      ...params
    }
  }).then(res => res.data)
}

// 青科展取用户需要必须完善的信息 有lang，并且lang为空，就必须选语言
export function getUserRequiredInfo (params) {
  return axios.get(`${base}/jingsai/sai_shi/get_user_required_info`, {
    params: {
      ...params
    }
  }).then(res => res.data)
}

// 青科展用户需要用户更新完善信息
export function updateUserRequiredInfo (params) {
  return axios.post(`${base}/jingsai/sai_shi/update_user_required_info`, {
    ...params
  }).then(res => res.data)
}
