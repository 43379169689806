export const mutations = {
  setFeedback (state, data) {
    state.status = Object.assign({}, state.status, data)
  },
  setInspire (state, data) {
    state.inspires = Object.assign({}, state.inspires, data)
  },
  clear (state) {
    state.status = {
      queId: '',
      code: -3,
      effect: false
    }
    state.inspires = {}
  }
}
