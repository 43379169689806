/**
 * codeup题库网络请求
 */
import axios from '@/plugins/axios'
const base = '/api'

// 练习答题-获取练习题目
export const getPracticeProblems = params => {
  return axios.get(`${base}/tiku/practice/problems`, {
    params: params
  })
    .then(res => res.data)
}
// 练习答题-获取练习信息
export const getPracticeInfo = params => {
  return axios.get(`${base}/tiku/practice/info`, {
    params: params
  })
    .then(res => {
      return res.data.practice
    })
}
// 练习答题-获取提交状态列表
export const getPracticeCommitState = params => {
  return axios.get(`${base}/tiku/practice/commit/states`, {
    params: params
  })
    .then(res => res.data)
}
// 获取历史已提交的作答明细列表
export const getPracticeHistory = params => {
  return axios.get(`${base}/tiku/practice/commit/history`, {
    params: params
  })
    .then(res => res.data)
}
// 教师 看学生历史已提交的作答
export const getPracticeHistoryTeacher = params => {
  return axios.get(`${base}/tiku/practice/commit/history_teacher`, {
    params: params
  })
    .then(res => res.data)
}
// 获取某次提交的作答明细
export const getAnyPracticeCommitDetail = params => {
  return axios.get(`${base}/tiku/practice/commit/detail`, {
    params: params
  })
    .then(res => res.data)
}
// 开始答题
export const startPractice = params => {
  return axios.post(`${base}/tiku/practice/start`, params)
    .then(res => res.data)
}
// 整卷提交
export const submitAllPractice = params => {
  return axios.post(`${base}/tiku/practice/commit_all`, {
    ...params
  })
    .then(res => res.data)
}
// 单题提交
export const submitSoloPractice = params => {
  return axios.post(`${base}/tiku/practice/commit_problem`, {
    ...params
  })
    .then(res => {
      return res
    })
}
// 练习答题-获取首次开始时间
// GET  /tiku/practice/get_start_time
export const getExamStartTime = params => {
  return axios.get(`${base}/tiku/practice/get_start_time`, {
    params: params
  })
    .then(res => res.data)
}
// 通用练习，获取时间接口
export const getPracticeTime = params => {
  return axios.get(`${base}/tiku/practice/time`, {
    params: params
  })
    .then(res => res.data)
}
// 教师看板场次列表
export const getLiveList = params => {
  return axios.get(`${base}/tiku/practice/display/live_list`, {
    params: params
  })
    .then(res => res.data)
}
// 看板-学生排名列表
export const getLiveRankList = params => {
  return axios.get(`${base}/tiku/practice/display/live_rank`, {
    params: params
  })
    .then(res => res.data)
}
// 看板-学生做题情况
export const getLiveProblemList = params => {
  return axios.get(`${base}/tiku/practice/display/live_problem_data`, {
    params: params
  })
    .then(res => res.data)
}
// mqtt 鉴权
export const getMqttAuth = params => {
  return axios.get(`${base}/mqtt/get_auth`, {
    params: params
  })
    .then(res => res.data)
}
// 称号信息同步
export const AnsFeedback = params => {
  return axios.post(`${base}/tiku/practice/answer_feedback`, params)
    .then(res => res.data)
}
// 获取分享信息
export const sharePaperProblemWork = params => {
  return axios.post(`${base}/tiku/practice/works/share`, params)
    .then(res => res.data)
}
// 用户监考数据查询
export const getMonitor = params => {
  return axios.get(`${base}/tiku/monitor/user/data`, {
    params: params
  })
    .then(res => res.data)
}
// 用户监考数据上报（切屏，拍照）
export const updateMonitor = params => {
  return axios.post(`${base}/tiku/monitor`, params)
    .then(res => res.data)
}
// 获取小程序码
export const getWxaCode = params => {
  return axios.get(`${base}/tiku/wxa_code`, {
    params: params
  })
    .then(res => res.data)
}
// 获取第二机位mqtt
export const getSecondMqttAuth = params => {
  return axios.get(`${base}/tiku/mqtt/get_auth_user_id`, {
    params: params
  })
    .then(res => res.data)
}
// 获取用户的权限 包含用户的课程以及他的权限类型
export const getTikuPack = params => {
  return axios.get(`${base}/tiku/pack`, {
    params: params
  }).then(res => res.data)
}
export const getTikuTime = params => {
  return axios.get(`${base}/tiku/bi_sai/time`, {
    params: {
      ...params
    }
  }).then(res => res.data)
}
// 获取用户信息
export const getUserInfo = params => {
  return axios.get(`${base}/user/info`, {
  }).then(res => res.data)
}
