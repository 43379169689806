// 青科展api
import axios from '@/plugins/axios'
const base = '/api'
// 登录
export function dologin (params) {
  return axios.post(`${base}/qp/login`, {
    ...params
  })
    .then(res => res.data)
}
// 退出登录
export function logout (params) {
  return axios.post(`${base}/qp/logout`, {
    ...params
  })
    .then(res => res.data)
}
// 获取用户信息
export function getQpUserInfo (params) {
  return axios.get(`${base}/qp/user/info`, {
    params: params
  })
    .then(res => res.data)
}
// 获取练习列表
export function getContactList (params) {
  return axios.get(`${base}/qp/get_practice_list`, {
    params: params
  })
    .then(res => res.data)
}
// 获取视频列表
export function getVideoList (params) {
  return axios.get(`${base}/qp/get_video_list`, {
    params: params
  })
    .then(res => res.data)
}
// 获取自由练习题目模板id
export function getTemplateIds () {
  return axios.get(`${base}/qp/get_free_template_list`)
    .then(res => res.data)
}
