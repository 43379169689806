<template>
  <transition-group name="list" tag="div">
    <XesMessage
      :type="itme.type"
      :message="itme.message"
      :zIndex="itme.zIndex"
      :guid="itme.guid"
      :key="itme.guid"
      :index="index"
      @delete="handleDeleteQueue"
      v-for="(itme, index) in queue"
    ></XesMessage>
  </transition-group>
</template>

<script>
import XesMessage from './xes-message'
import { guid } from '@/common/utils/index'

export default {
  name: 'XesMessageGroup',

  components: {
    XesMessage
  },

  data () {
    return {
      // 队列
      queue: [],
      // 层级
      zIndex: 2500
    }
  },

  methods: {
    /**
     * 添加队列
     */
    addQueue (message, type) {
      this.zIndex += 1
      this.queue.push({
        zIndex: this.zIndex,
        message,
        type,
        guid: guid()
      })
    },

    /**
     * 清楚队列
     */
    handleDeleteQueue (guid) {
      this.queue = this.queue.filter(item => {
        if (item.guid === guid) {
          return false
        }
        return true
      })
    }
  }
}
</script>

<style lang="less" scoped>
.list-enter-active, .list-leave-active {
  transition: all 300ms;
}

.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(-40px);
}
</style>
