import './index.less'
import Vue from 'vue'

/**
 * Vue实例的弹框的方法
 * @param {String} options.title标题
 * @param {String | VNode} options.content 弹框的主题内容
 * @param {String} options.confirmButtonText 确认按钮的文案，默认为确定
 * @param {String} options.cancelButtonText 取消按钮的文案，默认为空字符串，不显示取消按钮
 * @return Promise 返回Promise，如果按下确认按钮执行then的回调，如果按下取消按钮，执行catch的回调用
 */
export function alert () {
  // XesAlert的构造函数
  const XesAlertConstructor = Vue.extend(XesAlert)
  // xesalert的单例，为什么放到install的作用域呢？我希望xesAlert是全局单例的
  let xesAlert = null
  return function (options = {}) {
    // 默认的配置项
    const defaultOptions = {
      // 对话框的标题
      title: '',
      // 对话框的主体内容
      content: null,
      // 确认按钮的文案
      confirmButtonText: '确定',
      // 取消按钮的文案
      cancelButtonText: '',
      // 确认的回调
      onConfirm: () => {},
      // 取消的回调
      onReject: () => {}
    }
    return new Promise((resolve, reject) => {
      if (!xesAlert) {
        // 如果实例不存在，创建实例
        xesAlert = new XesAlertConstructor({
          data: {
            options: {
              ...defaultOptions,
              ...options,
              onConfirm: resolve,
              onReject: reject
            }
          }
        })
        document.body.appendChild(xesAlert.$mount().$el)
      } else {
        xesAlert.$el.style.display = ''
        setTimeout(() => {
          xesAlert.$el.style.opacity = 1
          // 如果实例存在，不再创建新的实例，而是对现有的实例进行更新
          xesAlert.$data.options = {
            ...defaultOptions,
            ...options,
            onConfirm: resolve,
            onReject: reject
          }
          // 强制刷新组件
          xesAlert.$forceUpdate()
        })
      }
    })
  }
}

// 函数式组件
export const XesAlert = {
  render (h) {
    // 对话框的主题内容
    let content = null
    // 判断content是VNode还是String
    if (typeof this.options.content === 'string') {
      content = h('p', this.options.content)
    } else {
      content = this.options.content
    }
    const buttons = []
    if (this.options.confirmButtonText) {
      const confirmButtonTextType = this.options.confirmButtonTextType || 'default'
      buttons.push(
        h('div', {
          class: {
            'xes-alert-button': true
          }
        }, [
          h('xes-button', {
            props: {
              type: confirmButtonTextType
            },
            on: {
              click: () => {
                this.$el.style.opacity = 0
                setTimeout(() => {
                  this.$el.style.display = 'none'
                  this.options.onConfirm()
                }, 400)
              }
            }
          }, this.options.confirmButtonText)
        ])
      )
    }
    if (this.options.cancelButtonText) {
      buttons.push(
        h('div', {
          class: {
            'xes-alert-button': true
          }
        }, [
          h('xes-button', {
            on: {
              click: () => {
                this.$el.style.opacity = 0
                setTimeout(() => {
                  this.$el.style.display = 'none'
                  this.options.onReject()
                }, 400)
              }
            }
          }, this.options.cancelButtonText)
        ])
      )
    }
    return (
      h('transition', {
        props: {
          name: 'fade',
          appear: true,
          'appear-active-class': 'fadeIn',
          'enter-active-class': 'fadeIn',
          'leave-active-class': 'fadeOut'
        }
      }, [
        h('div', {
          class: {
            modal: true,
            animated: true,
            'is-active': true
          },
          style: {
            opacity: 1,
            transition: 'opacity 0.5s'
          }
        }, [
          h('div', {
            class: {
              'modal-background': true
            }
          }),
          h('div', {
            class: {
              'modal-container': true
            }
          }, [
            h('div', {
              class: {
                'modal-content': true
              },
              style: {
                width: '100%'
              }
            }, [
              h('div', {
                class: {
                  box: true
                }
              }, [
                h('article', [
                  h('h1', {
                    class: {
                      'xes-alert-header': true
                    }
                  }, this.options.title),
                  content,
                  h('div', {
                    class: {
                      'xes-alert-buttons': true
                    }
                  }, buttons)
                ])
              ])
            ])
          ])
        ])
      ])
    )
  }
}
