import { getLiveRankList, getLiveProblemList } from '@/http/codeupHttp'
export const actions = {
  /**
   * [fetchRankList 获取考试排名信息]
   * @return {[Promise]}
   */
  async fetchRankList ({ rootState, commit }) {
    const { pno, live_ids, show, stuId } = rootState.route.query
    const rankList = await getLiveRankList({
      practice_code: pno,
      live_ids: live_ids || '',
      show: show || '',
      stuId: stuId
    })
    commit('setRankList', rankList)
    return rankList
  },
  /**
   * [fetchRankList 获取题目作答情况]
   * @return {[Promise]}
   */
  async fetchProblemList ({ rootState, commit }) {
    const { pno, live_ids, show, stuId } = rootState.route.query
    const problemList = await getLiveProblemList({
      practice_code: pno,
      live_ids: live_ids || '',
      show: show || ''
    })
    commit('setProblemList', problemList)
    return problemList
  }
}
